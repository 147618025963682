import { clearEmptyFields, clearEmptyFieldsDeep, clearPrivateProps } from 'common/utils';
import { EsfFormValues } from '../types';
import { Calculation } from '../constants';
import { EsfMode } from 'features/esf';

/**
 * Предобработка данных формы, перед отправкой на бек (для валидации, для отправки)
 */
export function preprocessFormData(values: EsfFormValues) {
  const data = clearEmptyFieldsDeep({ ...values, '@type': values._version });

  delete data['cancelReason'];
  delete data['invoiceStatus'];
  if (Array.isArray(data.sellers)) {
    data.sellers.forEach((seller: any) => {
      delete seller.kogd;
      seller.tin ||= seller._taxpayer?.tin;
    });
  }

  if (Array.isArray(data.customers)) {
    data.customers.forEach((customer: any) => {
      customer.tin = customer._taxpayer?.tin;
    });
  }

  if (data._sellerAgentTaxpayer?.tin) {
    data.sellerAgentTin = data._sellerAgentTaxpayer?.tin;
  }

  if (data._customerAgentTaxpayer?.tin) {
    data.customerAgentTin = data._customerAgentTaxpayer?.tin;
  }

  if (data.consignor?._taxpayer?.tin) {
    data.consignor.tin = data.consignor._taxpayer.tin;
  }

  if (data.consignee?._taxpayer?.tin) {
    data.consignee.tin = data.consignee._taxpayer.tin;
  }

  // delete data.sellerParticipants;
  ['sellerParticipants', 'customerParticipants'].forEach((participants) => {
    if (Array.isArray(data[participants])) {
      data[participants].forEach((part: any) => {
        if (Array.isArray(part.productShares)) {
          part.productShares = part.productShares.map((prod: any, index: number) => {
            const {
              quantity,
              quantitativeQuantity,
              priceWithoutTax,
              exciseAmount,
              turnoverSize,
              ndsRate,
              ndsAmount,
              priceWithTax,
              additional,
            } = prod;
            return clearEmptyFields({
              productNumber: index + 1,
              quantity,
              quantitativeQuantity,
              priceWithoutTax,
              exciseAmount,
              turnoverSize,
              ndsRate,
              ndsAmount,
              priceWithTax,
              additional,
            });
          });
        }
      });
    }
  });

  return clearPrivateProps(data);
}

/**
 * Предобработка данных от бекнда, для создания формы (при открытии бланка)
 */
export function preprocessToFormData(data: any, mode: EsfMode): EsfFormValues {
  const { invoice, ...info } = data || {};
  const values: EsfFormValues = {
    ...(invoice || {}),
    registrationNumber: data?.registrationNumber,
    _mode: mode,
    _calcDirection: Calculation.DIRECT,
    _calcType: Calculation.AUTO,
    _version: data?.version,
    _stringInvoiceId: data?.stringInvoiceId,
    _invoiceStatus: data?.invoiceStatus,
    _info: info,
  };

  if (Array.isArray(values.sellers)) {
    values.sellers.forEach((seller: any) => {
      seller._taxpayer = { tin: seller.tin };
      seller.kogd = data.kogd;
      delete seller.tin;
    });
  }

  if (Array.isArray(values.customers)) {
    values.customers.forEach((customer: any) => {
      customer._taxpayer = { tin: customer.tin };
      delete customer.tin;
    });
  }

  if ((values as any).sellerAgentTin) {
    values._sellerAgentTaxpayer = { tin: (values as any).sellerAgentTin };
    delete (values as any).sellerAgentTin;
  }

  if ((values as any).customerAgentTin) {
    values._customerAgentTaxpayer = { tin: (values as any).customerAgentTin };
    delete (values as any).customerAgentTin;
  }

  if ((values as any).consignor?.tin) {
    values.consignor._taxpayer = { tin: (values as any).consignor?.tin };
    delete (values as any).consignor?.tin;
  }

  if ((values as any).consignee?.tin) {
    values.consignee._taxpayer = { tin: (values as any).consignee?.tin };
    delete (values as any).consignee?.tin;
  }

  return clearEmptyFields(values) as EsfFormValues;
}
