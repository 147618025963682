import React, { Fragment, useMemo } from 'react';
import clsx from 'clsx';
import s from './BigcompanyEditModal.module.scss';
import { ModalContent, ModalFooter, ModalHeader } from 'services/modal';
import { useLocale } from 'services/l10n';
import { ModalChildrenProps } from 'services/modal/types';
import { Form, Formik, FormikHelpers } from 'formik';
import { Button, FormField, FormInput, FormSelect } from 'ui';
import { mutationDictionaryBigCompanyUpdate } from '../api';
import { ToastService } from 'services/toasts';
import { solveErrorMessage } from 'common/form/helpers';
import { getBCCategoryOptions, getBCStatusOptions } from '../helpers';

type Props = ModalChildrenProps<boolean> & {
  className?: string;
  data?: any;
};

export const BigcompanyEditModal: React.FC<Props> = ({ className, onClose, data }) => {
  const locale = useLocale();
  const { loc, chooseByKey } = locale;

  const initialValues = useMemo(() => {
    return {
      ...data,
      name: chooseByKey(data, 'name'),
    };
  }, [data, chooseByKey]);

  const sibmitHandler = async (values: any, form: FormikHelpers<any>) => {
    const { tin, status, category } = values;
    try {
      const res = await mutationDictionaryBigCompanyUpdate({
        body: { tin, status, category },
      });
      typeof onClose === 'function' && onClose(true);
      return res;
    } catch (error) {
      ToastService.showError(solveErrorMessage(error));
      form.setSubmitting(false);
    }
  };

  const BCStatusOptions = useMemo(() => getBCStatusOptions(locale, false), [locale]);
  const BCCategoryOptions = useMemo(() => getBCCategoryOptions(locale, false), [locale]);

  return (
    <div className={clsx(className, s.wrapper)}>
      <ModalHeader>{loc('bigcomps.button.edit')}</ModalHeader>
      <Formik<any> initialValues={initialValues} onSubmit={sibmitHandler}>
        {({ values, isSubmitting }) => (
          <Form>
            <Fragment>
              <ModalContent className={s.content}>
                <FormField name="tin" label={loc('bigcomps.tin')} readOnly>
                  <FormInput />
                </FormField>
                <FormField name="name" label={loc('bigcomps.name')} readOnly>
                  <FormInput />
                </FormField>
                <FormField name="category" label={loc('bigcomps.category')}>
                  <FormSelect options={BCCategoryOptions} />
                </FormField>
                <FormField name="status" label={loc('bigcomps.status')}>
                  <FormSelect options={BCStatusOptions} />
                </FormField>
                <FormField name="createdAt" label={loc('bigcomps.createdAt')} readOnly>
                  <FormInput />
                </FormField>
                <FormField name="deactivatedAt" label={loc('bigcomps.deactivatedAt')} readOnly>
                  <FormInput />
                </FormField>
              </ModalContent>
              <ModalFooter>
                <Button
                  size="small"
                  variant="transparent"
                  onClick={() => typeof onClose === 'function' && onClose(false)}
                >
                  {loc('common.button.cancel')}
                </Button>
                <Button
                  type="submit"
                  size="small"
                  disabled={!(values.tin && values.status && values.category)}
                  loading={isSubmitting}
                >
                  {loc('common.button.save')}
                </Button>
              </ModalFooter>
            </Fragment>
          </Form>
        )}
      </Formik>
    </div>
  );
};
