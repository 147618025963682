import { API_URL } from 'common/constants';
import { clearEmptyFields } from 'common/utils';
import { DataQueryFunction, downloadFile, toPaginationParams } from 'rest';

export const queryBigCompanyExport: DataQueryFunction = (variables: any) => {
  const { filter = {}, pagination } = variables || {};

  return downloadFile({
    url: `${API_URL}/dictionary/bigCompany/export`,
    method: 'GET',
    params: {
      filterItems: JSON.stringify(
        Object.entries(clearEmptyFields(filter)).map(([column, value]) => ({ column, value }))
      ),
      ...toPaginationParams(pagination),
      order: 'desc',
    },
  });
};
