import { FilterContentProps } from 'common/components';
import React, { Fragment, useMemo } from 'react';
import { useLocale } from 'services/l10n';
import { FormField, FormInput, FormSelect } from 'ui/common';
import { getBCCategoryOptions, getBCStatusOptions } from './helpers';

type Props = FilterContentProps;

export const BigcompaniesJournalFilters: React.FC<Props> = ({ form, classNames }) => {
  const locale = useLocale();
  const { loc } = locale;

  const { gridWrapper, gridSection } = classNames || {};

  const BCStatusOptions = useMemo(() => getBCStatusOptions(locale), [locale]);
  const BCCategoryOptions = useMemo(() => getBCCategoryOptions(locale), [locale]);

  return (
    <Fragment>
      <div className={gridWrapper}>
        <div className={gridSection} style={{ alignItems: 'start' }}>
          <div>
            <FormField name="TIN" label={loc('bigcomps.tin')}>
              <FormInput maxLength={12} onlyDigits />
            </FormField>
          </div>

          <div>
            <FormField name="CATEGORY" label={loc('bigcomps.category')} noMargin>
              <FormSelect options={BCCategoryOptions} />
            </FormField>
          </div>

          <div>
            <FormField name="STATUS" label={loc('bigcomps.status')} noMargin>
              <FormSelect options={BCStatusOptions} />
            </FormField>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
