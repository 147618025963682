import React from 'react';
import clsx from 'clsx';
import s from './SelectMethodModal.module.scss';
import { ModalT2Content, ModalT2Header } from 'services/modal';
import { useLocale } from 'services/l10n';
import { ButtonT2 } from 'ui';
import { API_URL } from 'common/constants';

type Props = {
  className?: string;
  onSelect: (method: 'CERT' | 'QR') => void;
  type?: 'AUTH' | 'SIGN';
};

export const SelectMethodModal: React.FC<Props> = ({ className, onSelect, type = 'AUTH' }) => {
  const locale = useLocale();
  const { loc } = locale;
  return (
    <div className={clsx(className, s.wrapper)}>
      <ModalT2Header>{loc(`common.${type}.method`)}</ModalT2Header>
      <ModalT2Content className={s.content}>
        <div className={s.container}>
          <ButtonT2 className={s.button} onClick={() => onSelect('CERT')}>
            {loc(`common.${type}.cert`)}
          </ButtonT2>
          <ButtonT2 className={s.button} onClick={() => onSelect('QR')}>
            {loc(`common.${type}.qr`)}
          </ButtonT2>
          {type === 'AUTH' && (
            <a className={clsx(s.button, s.egovLink)} href={`${API_URL}/oauth2/egov/authorization`}>
              <ButtonT2>{loc(`common.main.loginEgov`)}</ButtonT2>
            </a>
          )}
        </div>
      </ModalT2Content>
    </div>
  );
};
