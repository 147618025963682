import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import s from './FilterPanel.module.scss';
import { Button } from 'ui/common/controls/button';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { Badge, UITheme } from 'ui/common';
import { FilterClassNames } from './FilterContentProps';
import { clearPrivateProps, isEmpty, trimProps } from 'common/utils';
import { useLocale } from 'services/l10n';
import { solveFormErrors } from 'common/form/helpers';

type Props = {
  className?: string;
  initialValues?: any;
  validationSchema?: any;
  onSubmit?: (values: any) => Promise<any>;
  renderHeader?: () => React.ReactNode;
  hideHeader?: boolean;
  renderColumns?: (form: FormikProps<any>) => Array<React.ReactNode>; // depricated, использовать renderContent
  renderContent?: (props: { form: FormikProps<any>; classNames: FilterClassNames }) => React.ReactNode;
  onClear?: (form: FormikProps<any>) => void;
  buttonLabel?: string;
  submitButtonLabel?: string;
  clearButtonLabel?: string;
  showBadges?: boolean;
  initialOpened?: boolean;
  getFilterCount?: (values: any) => number;
  errors?: any;
  theme?: UITheme;
  expandable?: boolean;
  initialExpanded?: boolean;
  loading?: boolean;
};

export const FilterPanel: React.FC<Props> = ({
  className,
  initialValues = {},
  validationSchema,
  onSubmit = async (values: any) => console.log(values),
  renderColumns,
  renderContent,
  renderHeader,
  hideHeader,
  onClear = (form) => {
    form.resetForm();
    form.setValues({});
  },
  buttonLabel,
  submitButtonLabel,
  clearButtonLabel,
  showBadges = true,
  initialOpened = false,
  getFilterCount = (values: any) => {
    return Object.values(clearPrivateProps(values)).filter((value: any) => !isEmpty(value)).length;
  },
  errors,
  theme,
  expandable,
  initialExpanded = false,
  loading = false,
}) => {
  const locale = useLocale();
  const { loc } = locale;
  /**
   * Состояние панели открытая/закрытая. В закрытом состоянии форма полностью скрыта, удалена. При открытии создается новая форма.
   */
  const [opened, setOpened] = useState(initialOpened);
  /**
   * Состояние расширенная форма. Увеличивает размер панели с доп. полями фильтров.
   * При выключенном expanded, показаны только основные фильтры, доп. скрыты.
   * Форма остается одинаковой, только визуально скрываются поля.
   * Панель должна быть expandable для поддержки расширяемости, и использован класс gridSectionExpandalbe в компоненте фильтра
   */
  const [expanded, setExpanded] = useState(initialExpanded);
  const [expandedOverflowVisible, setExpandedOverflowVisible] = useState(false);

  const formRef = useRef<FormikProps<any> | undefined>(undefined);

  const submitHandler = async (values: any, form: FormikHelpers<any>) => {
    const trimedValues = trimProps(values);
    form.setValues(trimedValues, false);
    try {
      await onSubmit(trimedValues);
    } catch (error) {
      form.setErrors(solveFormErrors(error));
    }
  };

  const filterCount = getFilterCount(initialValues);

  useEffect(() => {
    // console.log('errors effect');
    formRef.current?.setErrors(solveFormErrors(errors));
  }, [errors]);

  useEffect(() => {
    const tm = setTimeout(() => setExpandedOverflowVisible(expanded), 500);
    return () => {
      clearTimeout(tm);
    };
  }, [expanded]);

  return (
    <div className={clsx(className, s.wrapper)}>
      {!hideHeader && (
        <div className={s.header}>
          {typeof renderHeader === 'function' && renderHeader()}
          <div className={s.filterButtonWrapper}>
            <Button
              className={s.filtersButton}
              variant="secondary"
              iconName={opened ? 'chevron-up' : 'chevron-down'}
              iconSide="right"
              iconClassName={s.chevronIcon}
              onClick={() => setOpened((state) => !state)}
            >
              {buttonLabel || loc('common.button.filters')}
            </Button>
            {showBadges && !opened && filterCount > 0 && <Badge number={filterCount} className={s.badge} />}
          </div>
        </div>
      )}
      {opened && (
        <Formik
          initialValues={initialValues}
          onSubmit={submitHandler}
          enableReinitialize={true}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={validationSchema}
        >
          {(form) => {
            formRef.current = form;
            return (
              <Form
                className={clsx(s.body, {
                  [s.expanded]: expanded,
                  [s.overflowVisible]: expandedOverflowVisible,
                })}
              >
                <div className={s.content}>
                  {renderContent
                    ? renderContent({
                        form,
                        classNames: {
                          columnClassName: s.column,
                          gridWrapper: s.gridWrapper,
                          gridSection: s.gridSection,
                          gridSectionExpandalbe: s.gridSectionExpandalbe,
                          gridDivider: s.gridDivider,
                          gridPaddingContainer: s.gridPaddingContainer,
                        },
                      })
                    : renderColumns
                    ? renderColumns(form).map((column, index) => (
                        <div key={index} className={s.column}>
                          {column}
                        </div>
                      ))
                    : null}
                </div>
                <div className={s.footer}>
                  <Button type="submit" variant="primary" size="small" theme={theme} disabled={loading}>
                    {submitButtonLabel || loc('common.button.search')}
                  </Button>
                  <Button variant="secondary" size="small" onClick={() => onClear(form)} theme={theme}>
                    {clearButtonLabel || loc('common.button.clear')}
                  </Button>
                  {expandable && (
                    <Button
                      className={s.expandButton}
                      iconClassName={s.expandButtonIcon}
                      size="small"
                      onClick={() => setExpanded((state) => !state)}
                      iconName={expanded ? 'chevron-up' : 'chevron-down'}
                      variant="transparent"
                    >
                      {expanded ? loc('common.button.hide') : loc('common.button.expand')}
                    </Button>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};
