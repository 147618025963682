import { action, computed, makeObservable, observable } from 'mobx';
import { RootStore } from 'common/stores/RootStore';
import { DT_FORMAT, formatDate } from 'services/date-time';
import { mutationCurrentUserLanguage } from 'rest';
import Cookies from 'js-cookie';
import { Lang, LANG_COOKIE_NAME, LocaleNames } from './helpers';
import { queryLocaleJson } from './api';

export class LocaleStore {
  rootStore: RootStore;
  lang: Lang = Lang.RU;

  private locales: {
    [Lang.RU]: { [key: string]: string };
    [Lang.KK]: { [key: string]: string };
  } = {
    [Lang.RU]: {},
    [Lang.KK]: {},
  };

  loadedLocales: { [key in Lang]: { [key in LocaleNames]?: boolean } } = {
    [Lang.RU]: {},
    [Lang.KK]: {},
  };

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      lang: observable,
      setLang: action,
      langAlt: computed,
    });
    this.rootStore = rootStore;
  }

  /**
   * Альтернатива lang в случае если локализация использует ключ 'kz' вместо 'kk'
   */
  get langAlt(): 'ru' | 'kz' {
    return this.lang === Lang.RU ? 'ru' : 'kz';
  }

  verifyLang = (lang: string): lang is Lang => {
    const alowedLangs: Array<string> = [Lang.RU, Lang.KK];
    if (alowedLangs.includes(lang)) {
      return true;
    }
    console.log(`Недопустимый язык ${lang}, допустимые языки ${alowedLangs}`);
    return false;
  };

  setLang = (lang: string) => {
    if (this.verifyLang(lang)) {
      this.lang = lang;
      Cookies.set(LANG_COOKIE_NAME, lang);
    }
  };

  /**
   * Устанавливаем язык по умолчанию, из куки "language"
   */
  setDefaultLang = () => {
    const defaultLang = Cookies.get(LANG_COOKIE_NAME);
    if (defaultLang) {
      this.setLang(defaultLang);
    }
  };

  /**
   * Устанавливает язык для текущей сессии пользователя, и язык по умолчанию для пользователя.
   * Запускает обновление данных пользователя, и обновляется язык в сторе (setLang)
   */
  updateSessionLang = async (lang: Lang): Promise<void> => {
    if (this.verifyLang(lang)) {
      await mutationCurrentUserLanguage({ lang });
      await this.rootStore.user.fetch();
    }
  };

  insertVars = (value: string, vars?: string | string[]): string => {
    if (!vars) {
      return value;
    }
    if (Array.isArray(vars)) {
      let result = value;
      vars.forEach((v, index) => {
        result = result.replace(`{${index}}`, v);
      });
      return result;
    } else {
      return value.replace('{0}', String(vars));
    }
  };

  /**
   * Возвращает локализацию по ключу
   * @param key ключ локализации
   * @param vars переменные (строка или массив строк) для вставки в локализацию вместо символов {0} {1} {2} ...
   * @param defaultValue строка, которую вернет метод, если локализация не найдена (по умолчанию возвращает ключ локализации)
   * @returns строка локализации. Если локализация не найдена, возвращает ключ локализации (если нет defaultValue). Если есть только ru локализация, но нет kk, то возващает русскую локализацию с добавлнием "(каз.)"
   */
  loc = (key: string, vars?: string | string[], defaultValue?: string): string => {
    const value = this.locales[this.lang][key];
    if (value) {
      return this.insertVars(value, vars);
    }
    if (this.lang === Lang.KK) {
      const ruValue = this.locales[Lang.RU][key];
      if (ruValue) return this.insertVars(`${ruValue} (каз.)`, vars);
    }
    return typeof defaultValue === 'string' ? defaultValue : key;
  };

  choose = ([nameRu, nameKz]: Array<string | undefined>): string => {
    return this.lang === Lang.RU ? nameRu || '' : nameKz || '';
  };

  /**
   * Выбирает значение локализации в объекте, по имени поля.
   * Например, 'name' -> выбирает между 'nameRu' и 'nameKz', в зависимости от языка;
   */
  chooseByKey = (obj: any, key: string) => this.choose([obj?.[`${key}Ru`], obj?.[`${key}Kz`]]);

  formatDateLocale = (value: string, to: string = DT_FORMAT.DEFAULT_DATE, from: string = DT_FORMAT.DEFAULT_DATE) => {
    return formatDate(value, to, from, this.lang);
  };

  fetchLocale = async (name: LocaleNames) => {
    const localeLang = this.lang;
    const res = await queryLocaleJson({ name, lang: localeLang });
    const bundle = res.data;
    if (bundle?.id && bundle?.lang) {
      console.log(`fetched bundle (${name}):`);
      this.setLocale(name, localeLang, bundle);
      return bundle;
    } else {
      console.error(`Ошибка в данных локалицации - ${name}`);
    }
  };

  setLocale = (name: LocaleNames, lang: Lang, bundle: { [key: string]: string }) => {
    if (name && lang && bundle) {
      Object.assign(this.locales[lang], bundle);
      this.loadedLocales[lang][name] = true;
    }
  };

  checkAndUpdateLocales = (localeNames: Array<LocaleNames>) => {
    const requests: Array<Promise<any>> = [];
    localeNames.forEach((name) => {
      if (!this.checkLocales([name])) {
        requests.push(this.fetchLocale(name));
      }
    });
    return Promise.allSettled(requests);
  };

  /**
   * Проверяем, что уже загружены ВСЕ локали из списка имен
   */
  checkLocales = (localeNames: Array<LocaleNames>): boolean => {
    return localeNames.every((name) => this.loadedLocales[this.lang][name]);
  };
}
