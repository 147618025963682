import React from 'react';
import clsx from 'clsx';
import s from '../../InsidePage.module.scss';
import { SvgIcon } from 'ui';
import { useLocale } from 'services/l10n';
import { InsideMenu } from '../../InsideMenu';
import { ReactComponent as Document } from 'assets/icons/document.svg';
import { InsideBreadcrumbs } from 'pages/inside-pages/InsideBreadcrumbs';

type Props = {
  className?: string;
};

export const GuidesPage: React.FC<Props> = ({ className }) => {
  const locale = useLocale();
  const { loc } = locale;
  return (
    <div className={clsx(className, s.wrapper)}>
      <InsideBreadcrumbs section="help" pageCrumbs={[{ label: loc('common.nav.guide') }]} />
      <div className={s.content}>
        <article className={s.article}>
          <h1>{loc('common.nav.guide')}</h1>

          <ul className={clsx(s.section, s.guides)}>
            <li>
              <a
                className={s.guideLink}
                href="https://kgd.gov.kz/sites/default/files/ftpdata/ESF/%D0%A0%D0%9F_%D0%98%D0%A1%20%D0%AD%D0%A1%D0%A4_%D0%BE%D0%B1%D0%BD%D0%BE%D0%B2%D0%BB%D0%B5%D0%BD%D0%BD%D0%BE%D0%B5_%D0%BD%D0%B0_29.08.2024.rar"
              >
                <SvgIcon className={s.docLinkIcon} component={Document} />
                {loc('common.guide.user')}
              </a>
            </li>
            <li>
              <a className={s.guideLink} href="https://kgd.gov.kz/sites/default/files/ESF/instrukcii.rar">
                <SvgIcon className={s.docLinkIcon} component={Document} />
                {loc('common.guide.bioReg')}
              </a>
            </li>
            <li>
              <a className={s.guideLink} href="https://kgd.gov.kz/sites/default/files/ftpdata/ESF/instrukcii.rar">
                <SvgIcon className={s.docLinkIcon} component={Document} />
                {loc('common.guide.bioSign')}
              </a>
            </li>
          </ul>
        </article>

        <InsideMenu menuName="help" className={s.insideMenu} />
      </div>
    </div>
  );
};
