import { LocaleStore } from 'services/l10n';

export enum BigcompanyCategory {
  BIG_COMPANY = 'BIG_COMPANY',
  SUBSOIL_USER = 'SUBSOIL_USER',
  OTHER = 'OTHER',
}

export const getBCCategoryOptions = (
  { loc }: LocaleStore,
  withEmpty: boolean = true
): Array<{ value: string | boolean; label: string }> => [
  ...(withEmpty
    ? [
        {
          value: '',
          label: loc('common.option.notSelected'),
        },
      ]
    : []),
  ...Object.values(BigcompanyCategory).map((category) => ({
    value: category,
    label: loc(`bigcomps.category.${category}`),
  })),
];

export const getBCStatusOptions = (
  { loc }: LocaleStore,
  withEmpty: boolean = true
): Array<{ value: string | boolean; label: string }> => [
  ...(withEmpty
    ? [
        {
          value: '',
          label: loc('common.option.notSelected'),
        },
      ]
    : []),
  {
    value: 'ACTIVE',
    label: loc('bigcomps.status.ACTIVE'),
  },
  {
    value: 'NOT_ACTIVE',
    label: loc('bigcomps.status.NOT_ACTIVE'),
  },
];
