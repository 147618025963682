import { FilterT2ContentProps, FormDoubleDatePickerT2 } from 'common/components';
import React from 'react';
import { useLocale } from 'services/l10n';
import { FieldT2, FormFieldT2, FormInputT2, FormMultiselect, FormSelectT2 } from 'ui';
import { getDocTypesOptions } from '../constants';
import { getOptionsYesNo } from 'common/helpers';

type Props = FilterT2ContentProps;

export const DocTransferFiltersAdvancedTxp: React.FC<Props> = ({ form, classNames }) => {
  const locale = useLocale();
  const { loc } = locale;

  const { advancedWrapper } = classNames || {};

  const docTypeOptions = getDocTypesOptions(locale);

  return (
    <div className={advancedWrapper}>
      <FieldT2 label={loc('docTransfer.date')}>
        <FormDoubleDatePickerT2 nameFrom="startDate" nameTo="endDate" placeholderText="" />
      </FieldT2>
      <FormFieldT2 name="taxpayerTin" label={loc('common.labels.tin')}>
        <FormInputT2 />
      </FormFieldT2>
      <FormFieldT2 name="agreementOrganizationTin" label={loc('docTransfer.agreementOrganizationTin')}>
        <FormInputT2 />
      </FormFieldT2>

      <FormFieldT2 name="isActive" label={loc('docTransfer.isActive')} fast={false}>
        <FormSelectT2 options={getOptionsYesNo(locale)} />
      </FormFieldT2>

      <FormFieldT2 name="docTypes" label={loc('docTransfer.docTypes')} fast={false}>
        <FormMultiselect options={docTypeOptions} checkOptionsChange variant="compact" theme="T2" />
      </FormFieldT2>
    </div>
  );
};
