import { PATH } from '../paths';
import { LAYOUT } from '../layouts';
import { RootRouteConfig } from '../types';
import { EaecLoginPage } from 'pages/eaec/eaec-login/EaecLoginPage';
import { DevPage } from 'pages/common/login/DevPage';
import { MainPage } from 'pages/main/MainPage';
import { InsideNewsListPage } from 'pages/inside-pages/news/InsideNewsListPage';
import { InsideNewsPage } from 'pages/inside-pages/news/InsideNewsPage';
import { GuidesPage } from 'pages/inside-pages/pages/guides/GuidesPage';
import { TestStandPage } from 'pages/inside-pages/pages/test-stend/TestStandPage';
import { ApiPage } from 'pages/inside-pages/pages/api/ApiPage';
import { VideoTutorPage } from 'pages/inside-pages/pages/video-tutor/VideoTutorPage';
import { ResetPasswordPage } from 'pages/main/reset-password/ResetPasswordPage';
import { ChangeEmailPage } from 'pages/main/change-email/ChangeEmailPage';
import { MainRegistrationPage } from 'pages/main/registration/MainRegistrationPage';

export const routesGuestConfig: RootRouteConfig = {
  layout: LAYOUT.EMPTY,
  routes: [
    {
      path: PATH.MAIN,
      exact: true,
      component: MainPage,
      layout: LAYOUT.GUEST,
      update: 'always',
    },
    {
      path: PATH.REGISTRATION,
      component: MainRegistrationPage,
      layout: LAYOUT.GUEST_NOFOOTER,
      update: 'always',
    },
    {
      path: PATH.RESET_PASSWORD,
      component: ResetPasswordPage,
      layout: LAYOUT.GUEST_NOFOOTER,
    },
    {
      path: PATH.CHANGE_EMAIL,
      component: ChangeEmailPage,
      layout: LAYOUT.GUEST_NOFOOTER,
    },
    {
      path: PATH.EAEC_LOGIN,
      component: EaecLoginPage,
      layout: LAYOUT.EAEC_LOGIN,
    },
    {
      path: PATH.PAGES,
      component: TestStandPage,
      layout: LAYOUT.GUEST,
      children: [
        // {
        //   path: PATH.TEST_STAND,
        //   component: TestStandPage,
        // },
        {
          path: PATH.API,
          component: ApiPage,
        },
        {
          path: PATH.VIDEO_TUTOR,
          component: VideoTutorPage,
        },
        {
          path: PATH.GUIDES,
          component: GuidesPage,
        },
        {
          path: PATH.INSIDE_NEWS_ITEM,
          component: InsideNewsPage,
          update: 'path',
        },
        {
          path: PATH.INSIDE_NEWS,
          component: InsideNewsListPage,
        },
      ],
    },
    {
      path: PATH.DEV,
      component: DevPage,
      exclude: process.env.NODE_ENV === 'production',
      layout: LAYOUT.ARM,
    },
    {
      path: '*',
      redirect: PATH.MAIN,
    },
  ],
};
