import { RootStore } from 'common/stores/RootStore';
import { action, makeObservable, observable } from 'mobx';
import { queryJMCountOfUnread } from 'rest';

/**
 * Стор функционала "Оповещения"
 */
export class NotificationsStore {
  rootStore: RootStore;
  countOfUnread: number = 0;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      countOfUnread: observable,
      setCountOfUnread: action,
      updateUnreadMessagesCount: action,
    });
    this.rootStore = rootStore;
  }

  setCountOfUnread(value: number) {
    this.countOfUnread = value;
  }

  updateUnreadMessagesCount = async () => {
    try {
      const { data } = await queryJMCountOfUnread();
      const count = parseInt(data);
      if (!isNaN(count)) {
        this.setCountOfUnread(count);
      }
    } catch (error) {
      throw error;
    }
  };
}
