import { clearEmptyFields } from 'common/utils';
import { DataQueryFunction, httpClient, toPaginationParams } from 'rest';

export const queryAdminDocTransferSearch: DataQueryFunction = async (variables: any) => {
  const { filter, pagination } = variables || {};

  const criteria = JSON.stringify(clearEmptyFields({ ...filter, isAdmin: true }));

  const res = await httpClient.get(`/admin/docTransfer/agreement/search`, {
    params: {
      criteria,
      ...toPaginationParams(pagination),
    },
  });
  if (res.data?.success === false || !res.data) {
    throw res;
  } else {
    const rows = res.data?.rows;
    if (Array.isArray(rows)) {
      res.data.rows = rows.map((row) => {
        let docTypes = [];
        try {
          docTypes = JSON.parse(row?.docTypes);
        } catch (error) {
          console.log('JSON.parse error');
        }
        return { ...row, docTypes };
      });
    }
    return res;
  }
};
