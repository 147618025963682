import { useAuth, useQueryParams } from 'common/hooks';
import { clearUrlParams } from 'common/utils';
import { useEffect, useMemo, useState } from 'react';

/**
 * Логика авторизации через Егов на главной странице
 */
export const useEgovLogin = () => {
  const auth = useAuth();
  const { egovIin } = useQueryParams();

  const egovIinVerified = useMemo(
    () => (typeof egovIin === 'string' && egovIin.match(/^\d{12}$/) ? egovIin : ''),
    [egovIin]
  );

  const [showEgovLogin, setShowEgovLogin] = useState(!!egovIinVerified);
  const [allowEgovLogin, setAllowEgovLogin] = useState(false);

  const submitEgovLogin = async (values: any) => {
    const { password } = values;
    if (allowEgovLogin) {
      await auth.login({ login: egovIinVerified, password, egovLogin: true });
    }
  };

  useEffect(() => {
    if (egovIinVerified) {
      auth.checkUserRegistration(egovIinVerified).then((isRegistred) => {
        if (isRegistred) {
          setAllowEgovLogin(true);
        } else {
          auth.offerUserRegistration().then((accepted) => {
            if (!accepted) {
              setShowEgovLogin(false);
            }
          });
        }
      });
    }
    if (egovIin) {
      clearUrlParams();
    }
  }, [egovIinVerified, auth, egovIin]);

  return {
    showEgovLogin,
    allowEgovLogin,
    submitEgovLogin,
    egovIinVerified,
  };
};
