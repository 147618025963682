import { action, computed, makeObservable, observable } from 'mobx';
import { RootStore } from 'common/stores/RootStore';
import {
  mutationEgovLogin,
  mutationLogin,
  mutationLoginCertInfo,
  mutationLogout,
  mutationMobileDocSignQr,
  mutationNoAuthSignQr,
  mutationSuperUserLogin,
  queryCheckRegisteredTypes,
  queryLoginTicket,
} from 'rest/api';
import { mutationEaecLogin, mutationEaecSignout } from 'rest/api/eaec';
import { API_URL } from 'common/constants';
import { preloadImage } from 'common/utils';
import { ToastService } from 'services/toasts';
import { solveErrorMessage } from 'common/form/helpers';
import { history } from 'services/history';
import { PATH } from 'routing/paths';

declare global {
  var Stomp: any;
  var SockJS: any;
}

type SignType = 'AUTH' | 'SIGN';
type SignMethod = 'CERT' | 'QR';

type SelectMethodModalState = {
  opened: boolean;
  type?: SignType;
  cb?: (method: SignMethod) => void;
  cancel?: (err: any) => void;
};

type QrCodeModalState = {
  opened: boolean;
  type?: SignType;
  qrUrl?: string;
  cancel?: (err: any) => void;
};

export class AuthStore {
  rootStore: RootStore;
  certificate: string = '';
  iin: string = '';
  xmlDsig: string = '';
  qrCodeModalState: QrCodeModalState = {
    opened: false,
  };
  selectMethodModalState: SelectMethodModalState = {
    opened: false,
  };
  /**
   * В процессе авторизации
   */
  inProgress: boolean = false;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      certificate: observable,
      iin: observable,
      qrCodeModalState: observable,
      selectMethodModalState: observable,
      inProgress: observable,
      loginFormShowed: computed,
      setIin: action,
      setCertificate: action,
      setQrCodeModalState: action,
      setSelectMethodModalState: action,
    });
    this.rootStore = rootStore;
  }

  /**
   * Старт процесса авторизации пользователя
   */
  startAuthProcess = () => {
    this.clearUserData();
    this.setSelectMethodModalState({
      opened: true,
      type: 'AUTH',
      cb: (method) => {
        if (method === 'QR') {
          this.getQRXmlSignature();
        } else {
          this.getCertificateXmlSignature();
        }
        this.setInProgress(true);
        this.setSelectMethodModalState({ opened: false });
      },
    });
  };

  login = async ({
    login,
    password,
    superAdmin,
    egovLogin,
  }: {
    login: string;
    password: string;
    superAdmin?: boolean;
    egovLogin?: boolean;
  }) => {
    let res;
    try {
      if (superAdmin) {
        res = await mutationSuperUserLogin({
          body: {
            login,
            password,
            certificate: this.certificate,
            superAdmin: true,
          },
        });
      } else if (egovLogin) {
        res = await mutationEgovLogin({
          body: {
            login,
            password,
          },
        });
      } else {
        res = await mutationLogin({
          body: {
            login,
            password,
            certificate: this.certificate,
            xmlDsig: this.xmlDsig,
          },
        });
      }
    } catch (error) {
      console.error('login error');
      throw error;
    }

    try {
      await this.rootStore.user.fetch();
      this.rootStore.user.setProfileSelectModal(true);
    } catch (error) {
      console.error('fetch user error');
      throw error;
    } finally {
      this.clearUserData();
    }
    return res;
  };

  logout = async () => {
    const { user } = this.rootStore;
    user.beforeLogout();
    try {
      let res;
      if (user.eaecUser?.isEaecUser) {
        res = await mutationEaecSignout();
      } else {
        res = await mutationLogout();
      }
      return res;
    } catch (error) {
      throw error;
    } finally {
      user.fetch();
    }
  };

  loginEaecUser = async (login: string, password: string) => {
    let res;
    try {
      res = await mutationEaecLogin({
        body: {
          login,
          password,
        },
      });
      this.rootStore.user.fetch();
      return res;
    } catch (error) {
      console.error('error ' + error);
      throw error;
    }
  };

  /**
   * Авторизация через ЭЦП
   */
  async getCertificateXmlSignature() {
    const { ncaLayer } = this.rootStore;
    try {
      const { data: ticket } = await queryLoginTicket();
      if (typeof ticket === 'string') {
        this.xmlDsig = await ncaLayer.getAuthXmlDsigDeprecated(ticket);
        this.getCertInfo();
      }
    } catch (error) {
      console.log('error', error);
      const message = solveErrorMessage(error);
      if (message !== 'action.canceled') {
        ToastService.showError(solveErrorMessage(error));
      }
    } finally {
      this.setInProgress(false);
    }
  }

  /**
   * Получение данных сертификата (ИИН, сертификат)
   */
  async getCertInfo() {
    if (this.xmlDsig) {
      const { data: info } = await mutationLoginCertInfo({ data: this.xmlDsig });
      const isRegistred = await this.checkUserRegistration(info.iin, info.tin);
      if (isRegistred) {
        this.setIin(info.iin);
        this.setCertificate(info.base64Cert || info.base64Pem);
      } else {
        this.offerUserRegistration();
      }
    }
  }

  setIin(iin: string) {
    this.iin = iin;
  }

  setCertificate(cert: string) {
    this.certificate = cert;
  }

  get loginFormShowed(): boolean {
    return !!(this.certificate && this.iin);
  }

  clearUserData() {
    this.setCertificate('');
    this.setIin('');
  }

  setInProgress(state: boolean) {
    this.inProgress = state;
  }

  async checkUserRegistration(iin: string, tin?: string): Promise<boolean> {
    let isRegistred: boolean = false;
    try {
      const { data } = await queryCheckRegisteredTypes({ iin, tin });
      isRegistred = !!(data.INDIVIDUAL || data.INDIVIDUAL_ENTREPRENEUR || (tin && data.PRIVATE_ENTERPRISE));
    } catch (error) {
      console.log('checkUserRegistration error', error);
    }
    return isRegistred;
  }

  async offerUserRegistration(): Promise<boolean> {
    const { locale, dialog } = this.rootStore;
    const { loc } = locale;
    const confirmed = await dialog.show({
      content: loc('common.error.regInvitation'),
      confirmButtonText: loc('common.yes'),
      rejectButtonText: loc('common.no'),
      title: loc('common.title.attention'),
      center: true,
    });
    if (confirmed) {
      history.push(PATH.REGISTRATION);
      return true;
    } else {
      return false;
    }
  }

  setQrCodeModalState = (state: QrCodeModalState) => {
    this.qrCodeModalState = state;
  };

  setSelectMethodModalState = (state: SelectMethodModalState) => {
    this.selectMethodModalState = state;
  };

  /**
   * Авторизация через QR код
   */
  getQRXmlSignature() {
    const that = this;
    const socket = new SockJS(`${API_URL}/websocket/qr`, null, {
      protocols_whitelist: ['websocket'],
    });
    let headers = {};
    const stompClient = window.Stomp.over(socket);
    stompClient.connect(
      headers,
      function (frame: any) {
        console.log('Connected: ' + frame);
        console.log('connected, session id: ', stompClient);
        console.log('!!!!here1');
        stompClient.subscribe('/user/queueAuth/qrAuth', function (message: any) {
          console.log('/user/queueAuth/qrAuth', message);
          that.xmlDsig = message.body;
          that.setQrCodeModalState({ opened: false });
          stompClient.disconnect();
          that
            .getCertInfo()
            .catch((error) => {
              ToastService.showError(solveErrorMessage(error));
            })
            .finally(() => that.setInProgress(false));
        });
        const qrUrl = `${API_URL}/mobileAuthSign/auth/qr`;
        preloadImage(qrUrl).finally(() => {
          that.setQrCodeModalState({
            opened: true,
            type: 'AUTH',
            qrUrl,
            cancel: () => {
              stompClient.disconnect();
              that.setInProgress(false);
            },
          });
        });
      },
      function (error: any) {
        console.error(error);
        const message = solveErrorMessage(error);
        if (typeof message === 'string') {
          ToastService.showError(message);
        }
        that.setInProgress(false);
      }
    );
  }

  /**
   * Метод для подписи документа/нескольких документов (с выбором метода)
   * data - строка или массив типа [{id: <id>, hash: <hash>}, ...]
   * */
  getSignature(data: any | Array<any>, docType?: string): Promise<{ cert: any; sign: string | any }> {
    const that = this;
    return new Promise<{ cert: any; sign: string }>((resolve, reject) => {
      that.setSelectMethodModalState({
        opened: true,
        type: 'SIGN',
        cb: (method) => {
          that.setSelectMethodModalState({ opened: false });
          if (method === 'QR') {
            console.log('QR sign method');
            that
              .getQRDocSignature(data, docType)
              .then((res) => resolve(res))
              .catch((e) => reject(e));
          } else {
            const { ncaLayer } = that.rootStore;
            ncaLayer
              .getSignature(data)
              .then((res) => resolve(res))
              .catch((e) => reject(e));
          }
        },
        cancel: (error) => reject(error),
      });
    });
  }

  getQRDocSignature(data: any | Array<any>, docType?: string): Promise<{ cert: any; sign: string }> {
    const that = this;

    // Для регистрации, нужно пользоваться сокетом для неавторизованного пользователя
    const forNotAuthedUser = docType === 'REGISTRATION';

    const wsUrl = forNotAuthedUser ? `${API_URL}/websocket/qr` : `${API_URL}/websocket/signQr`;

    const socket = new SockJS(wsUrl, null, {
      protocols_whitelist: ['websocket'],
    });
    let headers = {};
    const stompClient = window.Stomp.over(socket);
    return new Promise((resolve, reject) => {
      stompClient.connect(headers, function (frame: any) {
        console.log(socket);
        const sessionId = socket._transport.url.match(/Qr\/[^/]+\/([^/]+)\/websocket/i)?.[1];
        console.log('Connected: ' + frame);
        console.log('connected, session id: ', sessionId);
        const path = forNotAuthedUser ? '/user/queueAuth/qrAuth' : '/user/queueQrSign/signQr';
        stompClient.subscribe(path, function (message: any) {
          console.log(path, message);
          try {
            const res = JSON.parse(message.body);
            const sign = Array.isArray(data) ? res.signature : Object.values(res.signature)[0];
            console.log(path, res, sign);
            resolve({ sign, cert: res.certificate });
          } catch (e) {
            reject({ message: 'Sign data error' });
          } finally {
            stompClient.disconnect();
          }
          that.setQrCodeModalState({ opened: false });
          that.getCertInfo();
        });

        let body;
        if (Array.isArray(data)) {
          body = data.reduce((acc, { id, hash }) => {
            if (id && hash) {
              acc[id] = hash;
            }
            return acc;
          }, {});
        } else {
          body = { '1': data };
        }

        const signMutation = forNotAuthedUser ? mutationNoAuthSignQr : mutationMobileDocSignQr;

        signMutation({ body, wssId: sessionId, docType }).then((res) => {
          const qrUrl = URL.createObjectURL(res.data);
          that.setQrCodeModalState({
            opened: true,
            type: 'SIGN',
            qrUrl,
            cancel: (err) => {
              stompClient.disconnect();
              reject(err);
            },
          });
        });
      });
    });
  }
}
