import { contentYesNo, dateRangeValue, FilterT2Tag } from 'common/components';
import { LocaleStore } from 'services/l10n';
import { TableColumn } from 'ui/common';

export const getDocTransferColumnsTxp = ({ locale: { loc } }: { locale: LocaleStore }): Array<TableColumn> => [
  {
    name: 'agreementNumber',
    label: loc('docTransfer.agreementNumber'),
  },
  {
    name: 'taxpayerTin',
    label: loc('common.labels.tin'),
  },
  {
    name: 'taxpayerName',
    label: loc('common.labels.npName'),
  },
  {
    name: 'startDate',
    label: loc('docTransfer.startDate'),
  },
  {
    name: 'endDate',
    label: loc('docTransfer.endDate'),
  },
  {
    name: 'agreementOrganizationTin',
    label: loc('docTransfer.agreementOrganizationTin'),
  },
  {
    name: 'agreementOrganizationName',
    label: loc('docTransfer.agreementOrganizationName'),
  },
  {
    name: 'docTypes',
    label: loc('docTransfer.docTypes'),
    getContent: (row, index, value) =>
      Array.isArray(value) ? value.map((doc) => loc(`docTransfer.docType.${doc}`)).join(', ') : '',
  },
];

export const getDocTransferFilterTagsTxp = ({ locale }: { locale: LocaleStore }): Array<FilterT2Tag> => {
  const { loc } = locale;
  return [
    {
      name: ['startDate', 'endDate'],
      getValue: (values) => `${dateRangeValue(values.startDate, values.endDate)}`,
    },
    {
      name: 'taxpayerTin',
      label: loc('common.labels.tin'),
    },
    {
      name: 'agreementOrganizationTin',
      label: loc('docTransfer.agreementOrganizationTin'),
    },
    {
      name: 'isActive',
      label: loc('docTransfer.isActive'),
      getValue: (values) => {
        return contentYesNo(values.isActive, locale);
      },
    },
    {
      name: 'docTypes',
      label: loc('docTransfer.docTypes'),
      getValue: (values) => {
        return Array.isArray(values.docTypes)
          ? values.docTypes.map((doc: string) => loc(`docTransfer.docType.${doc}`)).join(', ')
          : undefined;
      },
    },
  ];
};
