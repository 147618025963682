import React, { useMemo } from 'react';
import clsx from 'clsx';
import s from './JournalT2Settings.module.scss';
import { Form, Formik, FormikHelpers } from 'formik';
import { TableColumn, UITheme } from 'ui/common';
import * as yup from 'yup';
import { ModalT2Content, ModalT2Footer, ModalT2Header } from 'services/modal';
import { ModalChildrenProps } from 'services/modal/types';
import { useLocale } from 'services/l10n';
import { ButtonT2, FormCheckboxT2, FormFieldT2 } from 'ui';

type Props = ModalChildrenProps & {
  className?: string;
  columns: Array<TableColumn>;
  setColumns: (columns: Array<TableColumn>) => void;
  theme?: UITheme;
  width?: number;
};

const schema = yup.object({});

// Убираем точки и брекеты из имен, чобы получить плоскую структуру в values формы
const encodeFieldName = (columnName: string) => {
  return columnName.replace(/[.[\]]/g, '$');
};

export const JournalT2Settings: React.FC<Props> = ({
  className,
  columns,
  setColumns,
  onClose,
  theme = 'T1',
  width,
}) => {
  const { loc } = useLocale();
  const submitHandler = (values: any, actions: FormikHelpers<any>) => {
    setColumns(
      columns.map((col) => ({
        ...col,
        hidden: !values[encodeFieldName(col.name)],
      }))
    );
    if (typeof onClose === 'function') onClose();
  };

  const initialValues = useMemo(
    () => Object.fromEntries(columns.map((col) => [encodeFieldName(col.name), !col.hidden])),
    [columns]
  );

  return (
    <div className={clsx(className, s.wrapper, s[theme])} style={{ width }}>
      <ModalT2Header>{loc('common.title.journalSettingsT2')}</ModalT2Header>

      <Formik initialValues={initialValues} onSubmit={submitHandler} validationSchema={schema}>
        {({ values }) => (
          <Form>
            <ModalT2Content className={s.content}>
              {/* {console.log(values)} */}
              {columns
                .filter((item) => !item.hideInSettings)
                .map((item) => (
                  <FormFieldT2
                    key={item.name}
                    name={encodeFieldName(item.name)}
                    direction="line-reverse"
                    className={s.field}
                    labelClassName={s.label}
                    label={item.label}
                  >
                    <FormCheckboxT2 />
                  </FormFieldT2>
                ))}
            </ModalT2Content>
            <ModalT2Footer theme={theme}>
              <ButtonT2 className={s.button} variant="transparent" onClick={onClose} size="small">
                {loc('common.button.cancel')}
              </ButtonT2>
              <ButtonT2
                // disabled={!isValid}
                type="submit"
                className={s.submit}
                size="small"
              >
                {loc('common.button.save')}
              </ButtonT2>
            </ModalT2Footer>
          </Form>
        )}
      </Formik>
    </div>
  );
};
