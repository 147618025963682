import { action, computed, makeObservable, observable } from 'mobx';
import { RootStore } from 'common/stores/RootStore';
import {
  queryAgreementsUnsigned,
  queryInvitations,
  queryIsgoInvoiceGetNotifications,
  queryMessagesGetMessageForConfirmation,
} from 'rest';

export type UserCheckType = 'invite' | 'agreement' | 'sntExpired' | 'isgoNotifications' | 'messageForConfirmation';

export class UserChecksStore {
  rootStore: RootStore;
  checksQueue: Array<{ type: UserCheckType; data: any }> = [];
  currentCheckIndex: number = 0;
  armInvite: undefined | any;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      checksQueue: observable,
      currentCheckIndex: observable,
      currentCheckModal: computed,
      pushToCheckQueue: action,
      nextCheck: action,
      armInvite: observable,
      setArmInvite: action,
    });
    this.rootStore = rootStore;
  }

  afterLoginChecks = () => {
    console.log('!!! afterLoginChecks');
    this.checkArmInvite();
    this.checkUserAgreements();
    // this.checkExpiredSNT(); эта фукциональность не работает Это можно не делать пока
    this.checkIsGoNotification();
    this.checkMessageForConfirmation();
  };

  get currentCheckModal(): { type: UserCheckType; data: any } | null {
    return this.currentCheckIndex in this.checksQueue ? this.checksQueue[this.currentCheckIndex] || null : null;
  }

  pushToCheckQueue = (check: { type: UserCheckType; data: any }) => {
    this.checksQueue = [...this.checksQueue, check];
  };

  nextCheck = () => {
    if (this.checksQueue[this.currentCheckIndex + 1]) {
      this.currentCheckIndex++;
    } else {
      this.checksQueue = [];
      this.currentCheckIndex = 0;
    }
  };

  checkArmInvite = async () => {
    try {
      const { data } = await queryInvitations();
      console.log({ data });
      if (Array.isArray(data) && data[0]?.permissions?.length) {
        this.setArmInvite(data[0]);
      }
    } catch (error) {}
  };

  setArmInvite = (data: any) => {
    this.armInvite = data;
  };

  showArmInvite = () => {
    if (this.armInvite) {
      this.pushToCheckQueue({ type: 'invite', data: this.armInvite });
      // this.setArmInvite(undefined);
    }
  };

  /**
   * Проверка неподписанное соглашение
   */
  checkUserAgreements = async () => {
    const currentUser = this.rootStore.user.currentUser;
    if (currentUser && !currentUser?.hasUnsingedAgreements) {
      try {
        const { data } = await queryAgreementsUnsigned();
        console.log({ data });
        if (Array.isArray(data?.data)) {
          let agreementData: Array<any> = data?.data.filter((item: any) => item.tin === currentUser.currentUserTin);
          if (currentUser.currentProfileId === 'individual') {
            agreementData = agreementData.filter((item: any) => item.registrationType === 'INDIVIDUAL');
          }
          console.log({ agreementData });
          if (agreementData.length) {
            agreementData.forEach((aggr) => this.pushToCheckQueue({ type: 'agreement', data: aggr }));
          }
        }
      } catch (error) {}
    }
  };

  // /**
  //  * Извещение о нарушении сроков подтверждения (не подтверждения) получения или отклонения СНТ
  //  */
  // checkExpiredSNT = async () => {
  //   try {
  //     const { data } = await querySntExpiredUnsigned();
  //     console.log({ data });
  //     if (Array.isArray(data) && data.length) {
  //       this.pushToCheckQueue({ type: 'sntExpired', data });
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  /** Проверка наличия у юзера ИС ГО уведомлений */
  checkIsGoNotification = async () => {
    try {
      const { data } = await queryIsgoInvoiceGetNotifications();
      console.log('checkIsGoNotification', { data });
      if (Array.isArray(data) && data.length) {
        data.forEach((notification) => this.pushToCheckQueue({ type: 'isgoNotifications', data: notification }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  /** Отображение диалогового окна с системным сообщением / сообщением администратора, требующим подтверждения
   * может вызываться несколько раз, пока не будут прочитаны все сообщения
   * */
  checkMessageForConfirmation = async () => {
    try {
      const { data } = await queryMessagesGetMessageForConfirmation();
      console.log('checkMessageForConfirmation', { data });
      if (data.createdTimeStamp) {
        this.pushToCheckQueue({ type: 'messageForConfirmation', data });
      }
    } catch (error) {
      console.log(error);
    }
    //
    // function showMessageForConfirmation() {
  };
}
