import { clearEmptyFields } from 'common/utils';
import { DataQueryFunction, httpClient, toPaginationParams } from 'rest';

export const queryBigCompanyFindAll: DataQueryFunction = async (variables: any) => {
  const { filter, pagination } = variables || {};

  const res = await httpClient.get(`/dictionary/bigCompany/findAll`, {
    params: {
      // views: JSON.stringify(['tin', 'name']),
      filterItems: JSON.stringify(
        Object.entries(clearEmptyFields(filter)).map(([column, value]) => ({ column, value }))
      ),
      ...toPaginationParams(pagination),
      order: 'desc',
    },
  });
  if (res.data?.success === false || !res.data) {
    throw res;
  } else {
    return res;
  }
};
