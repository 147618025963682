import React, { Fragment, useMemo } from 'react';
import { JournalButton, JournalT2 } from 'common/components/journal';
import { downloadFile, useDataQuery } from 'rest';
import { useLocale } from 'services/l10n';
import { getDocTransferColumnsTxp, getDocTransferFilterTagsTxp } from './getDocTransferColumnsTxp';
import { DocTransferFiltersAdvancedTxp } from './DocTransferFiltersAdvancedTxp';
import { SessionStorageService } from 'services/storage/StorageService';
import { DocTransferFiltersMainTxp } from './DocTransferFiltersMainTxp';
import { queryDocTransferSearch } from '../api';
import { PATH } from 'routing/paths';
import { ReactComponent as PdfIcon } from 'assets/icons/journal-buttons/export-pdf.svg';
import { API_URL } from 'common/constants';

const initialPagination = { rows: 15 };

const getRowId = (row: any) => row?.agreementId;

const filterStorageKey = 'docTransferFilters';

type Props = {};

/**
 * Страница НП -> Настройки -> Журнал соглашений (на передачу СНТ, АВР, ЭД и ЭСФ)
 * /doc-transfer
 */
export const DocTransferJournalTxp: React.FC<Props> = () => {
  const locale = useLocale();
  const { loc } = locale;

  const columns = useMemo(() => getDocTransferColumnsTxp({ locale }), [locale]);

  const filterTags = useMemo(() => getDocTransferFilterTagsTxp({ locale }), [locale]);

  const initialFilter = useMemo(() => SessionStorageService.get(filterStorageKey) || {}, []);

  const queryProps = useDataQuery(queryDocTransferSearch, {
    filter: initialFilter,
    pagination: initialPagination,
  });

  const headerButtons: Array<JournalButton> = [];

  const actionButtons: Array<JournalButton> = [
    {
      id: 'pdf',
      label: loc('common.button.pdf'),
      icon: PdfIcon,
      onClick: async (selection) => {
        try {
          const res = await downloadFile({
            url: `${API_URL}/docTransfer/agreement/report`,
            method: 'GET',
            params: { agreementId: getRowId(selection[0]) },
          });
          return res;
        } catch (error) {
          console.log(error);
        }
      },
    },
  ];

  return (
    <Fragment>
      <JournalT2
        pageTitle={loc('common.menu.docTransfer')}
        columns={columns}
        queryProps={queryProps}
        filterComponentMain={DocTransferFiltersMainTxp}
        filterComponentAdvanced={DocTransferFiltersAdvancedTxp}
        filterPanelType="aside"
        headerButtons={headerButtons}
        actionButtons={actionButtons}
        getRowId={getRowId}
        selectable
        filterStorageKey={filterStorageKey}
        filterTags={filterTags}
        breadcrumbs={[
          { label: loc('common.main.main'), link: PATH.MAIN },
          { label: loc('common.menu.settings') },
          { label: loc('common.menu.docTransfer') },
        ]}
      />
    </Fragment>
  );
};
