import { action, makeObservable, observable } from 'mobx';
import {
  queryMessagesFilter,
  queryMessagesGetInbound,
  queryMessagesGetOutbound,
  queryMessagesNewInboundCount,
} from 'rest';

/**
 * Стор функционала "Сообщения"
 */
export class MessagesStore {
  incomingMessages: Array<any> | null = null;
  outcomingMessages: Array<any> | null = null;
  filteredMessages: Array<any> | null = null;
  countOfUnread: number = 0;

  constructor() {
    makeObservable(this, {
      incomingMessages: observable,
      outcomingMessages: observable,
      filteredMessages: observable,
      countOfUnread: observable,
      setIncomingMessages: action,
      setOutcomingMessages: action,
      setFilteredMessages: action,
      setCountOfUnread: action,
    });
  }

  setIncomingMessages(value: Array<any> | null) {
    this.incomingMessages = value;
  }

  setOutcomingMessages(value: Array<any> | null) {
    this.outcomingMessages = value;
  }

  setFilteredMessages(value: Array<any> | null) {
    this.filteredMessages = value;
  }

  setCountOfUnread(value: number) {
    this.countOfUnread = value;
  }

  fetchIncomingMessages = async () => {
    const { data } = await queryMessagesGetInbound();
    if (Array.isArray(data)) {
      this.setIncomingMessages(data);
    }
    this.setCountOfUnread(0);
    return data;
  };

  fetchOutcomingMessages = async () => {
    const { data } = await queryMessagesGetOutbound();
    if (Array.isArray(data)) {
      this.setOutcomingMessages(data);
    }
    return data;
  };

  fetchFilteredMessages = async (filter: { [key: string]: string }) => {
    const { data } = await queryMessagesFilter({ filter });
    if (Array.isArray(data)) {
      this.setFilteredMessages(data);
    }
    return data;
  };

  fetchUnreadCounter = async () => {
    const { data } = await queryMessagesNewInboundCount();
    const count = parseInt(data);
    if (!isNaN(count)) {
      this.setCountOfUnread(count);
    }
    return data;
  };
}
