import { LocaleStore } from 'services/l10n';
import { TableColumn } from 'ui/common';

export const getBigcompaniesJournalColumns = ({
  locale: { loc, chooseByKey },
}: {
  locale: LocaleStore;
}): Array<TableColumn> => [
  {
    name: 'tin',
    label: loc('bigcomps.tin'),
  },

  {
    name: 'name',
    label: loc('bigcomps.name'),
    getContent: (row) => chooseByKey(row, 'name'),
    width: 350,
  },
  {
    name: 'category',
    label: loc('bigcomps.category'),
    getContent: (row) => (row.category ? loc(`bigcomps.category.${row.category}`) : ''),
  },
  {
    name: 'status',
    label: loc('bigcomps.status'),
    getContent: (row) => (row.status ? loc(`bigcomps.status.${row.status}`) : ''),
  },
  {
    name: 'createdAt',
    label: loc('bigcomps.createdAt'),
  },
  {
    name: 'deactivatedAt',
    label: loc('bigcomps.deactivatedAt'),
  },
];
