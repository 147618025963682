import { DataMutationFunction } from '../../types';
import { httpClient } from '../../httpClient';
import { convertToFormData } from '../../helpers';

export const mutationPreferences: DataMutationFunction = async (variables = {}): Promise<any> => {
  const { body = {} } = variables;

  const formData = convertToFormData(body);

  const res = await httpClient.put('/preferences', formData);
  return res;
};
