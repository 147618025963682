import React from 'react';
import clsx from 'clsx';
import s from './GuestLayoutHeader.module.scss';
import { Lang, useLocale } from 'services/l10n';
import { Link } from 'react-router-dom';
import { PATH } from 'routing/paths';
import { KgdLogo } from 'ui/common/elements/logo';
import { LangToggleT2 } from 'common/components';
import { LEGACY_URL, SUPPORT_EMAIL, SUPPORT_PHONE } from 'common/constants';

type Props = {
  className?: string;
};

export const GuestLayoutHeader: React.FC<Props> = ({ className }) => {
  const locale = useLocale();
  const { loc, lang } = locale;

  return (
    <div className={clsx(className, s.wrapper)}>
      <div className={s.inner}>
        <Link to={PATH.MAIN} className={s.logoLink}>
          <KgdLogo />
        </Link>
        <nav className={s.nav}>
          <div className={s.navRow}>
            <a href="https://kgd.gov.kz/">{loc('common.link.kgd')}</a>
            <a href="https://www.gov.kz/">{loc('common.link.mfkz')}</a>
            {/* <LogButton className={s.logButton} action="login" reversedColor /> */}
            <a className={s.oldVersion} href={`${LEGACY_URL}/login`}>
              {loc('common.oldVersionLink')}
            </a>
          </div>
          <div className={s.navRow}>
            <Link className={s.navLink} to={`${PATH.PAGES}${PATH.INSIDE_NEWS}`}>
              {loc('common.nav.news')}
            </Link>
            <Link className={s.navLink} to={`${PATH.PAGES}${PATH.VIDEO_TUTOR}`}>
              {loc('common.nav.tutor')}
            </Link>
            <Link className={s.navLink} to={`${PATH.PAGES}${PATH.API}`}>
              {loc('common.nav.developers')}
            </Link>
            <Link className={s.navLink} to={`${PATH.PAGES}${PATH.GUIDES}`}>
              {loc('common.nav.guide')}
            </Link>
            {process.env.NODE_ENV === 'development' && (
              <Link className={s.navLink} to={`${PATH.DEV}`}>
                Dev Page
              </Link>
            )}

            <div className={s.support} style={lang === Lang.KK ? { gap: '0 12px' } : undefined}>
              <div className={s.supportRow}>
                <span className={s.label}>{loc('common.layout.support')}: </span>
                <span className={s.phone}>{SUPPORT_PHONE} </span>
                <span className={s.brackets}>({loc('common.layout.hours')}),</span>
              </div>
              <div className={s.supportRow}>
                <a className={s.mail} href={`mailto:${SUPPORT_EMAIL}`}>
                  {SUPPORT_EMAIL}{' '}
                </a>
                <span className={s.brackets}>({loc('common.layout.24.7')})</span>
              </div>
            </div>
            <LangToggleT2 className={s.langToggle} reversedColor />
          </div>
        </nav>
      </div>
    </div>
  );
};
