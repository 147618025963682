import { isAppMode } from 'common/constants';
import { solveErrorMessage } from 'common/form/helpers';
import { parse } from 'query-string';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { ToastService } from 'services/toasts';
import { DocumentTargetUrl, getDocumentTargetUrl, isLinkType, queryDocumentFindIdByRegNumber } from '.';

type Props = {};

/**
 * Страница для загрузки документа (по рег. номеру или id)
 * Если id неизвестен, то запрашиваем id по рег. номеру и формируется ссылка на реальный урл документа
 */
export const DocumentLinkPage: React.FC<Props> = () => {
  const history = useHistory();
  const queryParams = parse(history.location.search);
  const { linkType, regNumber, appMode: appModeParam } = queryParams;
  const [loading, setLoading] = useState(true);

  console.log('loading', loading);

  const resolvelink = async () => {
    if (isAppMode(appModeParam) && typeof linkType === 'string' && isLinkType(linkType)) {
      try {
        const res = await queryDocumentFindIdByRegNumber({
          registrationNumber: regNumber,
        });

        const link = getDocumentTargetUrl({
          docType: res.data?.documentType,
          linkType: linkType,
          id: res.data?.documentId,
          appMode: appModeParam,
          regNumber: typeof regNumber === 'string' ? regNumber : undefined,
        });
        console.log(res, link);
        redirectToLink(link);
      } catch (error) {
        ToastService.showError(solveErrorMessage(error));
        setLoading(false);
      }
    }
    setLoading(false);
  };

  const redirectToLink = (link: DocumentTargetUrl) => {
    if (link.url) {
      if (link.outsideLink) {
        window.location.assign(link.url);
      } else {
        history.push(link.url);
      }
    } else {
      ToastService.showError('Документ не найден');
    }
  };

  useEffect(() => {
    resolvelink();
  }, []); // eslint-disable-line

  return null;
};
