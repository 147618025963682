import { action, computed, makeObservable, observable } from 'mobx';
import { RootStore } from 'common/stores/RootStore';
import { mutationPreferences, queryCurrentUser, queryEnterpriseChange } from 'rest/api';
import { AdminPermission, USER_SERVICE_ROLE } from 'common/constants';
import { CurrentUser, EaecUser, UserPreferences } from './types';
import { Profile } from './types';
import { ToastService } from 'services/toasts';

export class UserStore {
  rootStore: RootStore;
  currentUser: CurrentUser | undefined;
  eaecUser: EaecUser | undefined;
  adminPermissions: { [key: string]: true } = {};
  permissions: { [key: string]: true } = {};
  /**
   * Показывать модалку выбора профиля.
   */
  profileSelectModal: boolean = false;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      currentUser: observable.ref,
      eaecUser: observable.ref,
      isAuthed: computed,
      isEaecUser: computed,
      isUserAuthed: computed,
      clear: action,
      normalize: action,
      profileSelectModal: observable,
      setProfileSelectModal: action,
      updatePreferences: action,
    });
    this.rootStore = rootStore;
  }

  setProfileSelectModal = (opened: boolean) => (this.profileSelectModal = opened);

  /**
   * проверка что любой пользователь авторизован (Обычный или ЕАЕС пользователь, или Супер пользователь)
   */
  get isAuthed(): boolean {
    return !!this.currentUser || !!this.eaecUser;
  }

  /**
   * пользователь авторизован и он ЕАЕС пользователь
   */
  get isEaecUser(): boolean {
    return this.eaecUser?.isEaecUser || false;
  }

  /**
   * обычный пользователь авторизован (не ЕАЕС)
   */
  get isUserAuthed(): boolean {
    return !!this.currentUser;
  }

  fetch = async () => {
    try {
      const res = await queryCurrentUser();
      this.normalize(res.data);
      this.setUserLang();
      return res;
    } catch (error) {
      this.clear();
      console.error(error);
    }
  };

  normalize = (data: any) => {
    let userData;
    try {
      userData = JSON.parse(data.currentUser);
    } catch (error) {
      console.log('error parsing User data');
    }

    try {
      userData.blockingActions = data.blockingActions && JSON.parse(data.blockingActions);
      userData.currentUserProject = data.currentUserProject && JSON.parse(data.currentUserProject);
      userData.manualCurrencyRateTins = data.manualCurrencyRateTins && JSON.parse(data.manualCurrencyRateTins);
      userData.isGU = data.isGU && JSON.parse(data.isGU);
    } catch (error) {
      console.log('error parsing User Root data');
    }
    userData.currentProfile = this.getCurrentProfile(userData.profiles, userData.currentProfileId);
    userData.hasUnsingedAgreements = !!data.hasUnsingedAgreements;

    console.log('userData', userData);

    this.clear();
    if (userData?.isEaecUser) {
      this.eaecUser = userData;
    } else {
      this.currentUser = userData;

      // Парсинг настроек пользователя
      try {
        if (this.currentUser?.preferences) {
          const preferences = JSON.parse((this.currentUser?.preferences as unknown as string) || '{}');

          this.currentUser.preferences = {
            language_default: preferences.language_default,
            // branch_preferences, grid_preferences - настройки пользователя из старого фронта (не должны изменяться)
            branch_preferences: JSON.parse(preferences.branch_preferences || '{}'),
            grid_preferences: JSON.parse(preferences.grid_preferences || '{}'),
            // настройки нового фронта
            journals: JSON.parse(preferences.journals || '{}'),
          };
        }
      } catch (error) {
        console.error('error parsing User Preferences data', error);
      }
      this.adminPermissions = Object.fromEntries(
        this.currentUser?.adminPermissions && Array.isArray(this.currentUser?.adminPermissions)
          ? this.currentUser.adminPermissions.map((p: string) => [p, true])
          : []
      );
      this.permissions = Object.fromEntries(
        this.currentUser?.permissions && Array.isArray(this.currentUser?.permissions)
          ? this.currentUser.permissions.map((p: string) => [p, true])
          : []
      );
    }
  };

  setUserLang = () => {
    const userLang = this.currentUser?.preferences?.language_default;
    if (userLang) {
      console.log('set user language', userLang);
      this.rootStore.locale.setLang(userLang);
    }
  };

  clear = () => {
    this.currentUser = undefined;
    this.eaecUser = undefined;
    this.adminPermissions = {};
    this.permissions = {};
  };

  /**
   * Проверяет, есть ли права у АРМ пользователя
   * @param permissions Имя права или массив с правами
   * @param every если true, то проверяет что есть ВСЕ права из массива (every). По умолчанию false, проверяет что есть хотя бы одно право из массива (some)
   * @returns true/false
   */
  checkAdminPermission = (permissions: Array<AdminPermission> | AdminPermission, every: boolean = false) => {
    const perms = Array.isArray(permissions) ? permissions : [permissions];
    if (every) {
      return perms.every((p) => this.adminPermissions[p]);
    } else {
      return perms.some((p) => this.adminPermissions[p]);
    }
  };

  /**
   * Проверяет, есть ли права у НП
   * @param permissions Имя права или массив с правами
   * @param every если true, то проверяет что есть ВСЕ права из массива (every). По умолчанию false, проверяет что есть хотя бы одно право из массива (some)
   * @returns true/false
   */
  checkPermission = (permissions: Array<string> | string, every: boolean = false) => {
    const perms = Array.isArray(permissions) ? permissions : [permissions];
    if (every) {
      return perms.every((p) => this.permissions[p]);
    } else {
      return perms.some((p) => this.permissions[p]);
    }
  };

  isSuperUser = () => {
    return this.currentUser?.userServiceRoleType === USER_SERVICE_ROLE.SUPER_ADMIN;
  };

  changeProfile = async (profileId: string) => {
    this.beforeLogout();
    await queryEnterpriseChange({ profileId });
    await this.fetch();
    this.rootStore.userChecks.afterLoginChecks();
  };

  getCurrentProfile = (profiles?: Array<Profile>, currentProfileId?: string): Profile | undefined => {
    const userProfiles = profiles || this.currentUser?.profiles;
    const userCurrentProfileId = currentProfileId || this.currentUser?.currentProfileId;
    if (Array.isArray(userProfiles)) {
      const profile = userProfiles.find((prof) => prof.id === userCurrentProfileId);
      return profile;
    }
  };

  /**
   * Действия перед выходом из профиля
   */
  beforeLogout = () => {
    ToastService.reset();
  };

  updatePreferences = async (update: (preferences: UserPreferences) => UserPreferences) => {
    if (typeof update === 'function' && this.currentUser?.preferences) {
      const newPreferences = update(this.currentUser?.preferences);
      this.currentUser.preferences = { ...newPreferences };

      // Удаляем старые настройки журналов, они не уже используются
      (newPreferences.grid_preferences.columns_old as any) = {};

      console.log({ newPreferences });

      try {
        await mutationPreferences({
          body: {
            preferences: JSON.stringify({
              language_default: newPreferences.language_default,
              //branch_preferences,grid_preferences - настройки пользователя из старого фронта
              branch_preferences: JSON.stringify(newPreferences.branch_preferences),
              grid_preferences: JSON.stringify(newPreferences.grid_preferences),
              // настройки нового фронта
              journals: JSON.stringify(newPreferences.journals),
            }),
          },
        });
      } catch (error) {
        console.log(error);
      }
    }
  };
}
