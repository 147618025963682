import React, { Fragment, useMemo } from 'react';
import clsx from 'clsx';
import s from './BigcompanyEditModal.module.scss';
import { ModalContent, ModalFooter, ModalHeader } from 'services/modal';
import { useLocale } from 'services/l10n';
import { ModalChildrenProps } from 'services/modal/types';
import { Form, Formik, FormikHelpers } from 'formik';
import { Button, Field, FormField, FormSelect, Input } from 'ui';
import { mutationDictionaryBigCompanyAdd } from '../api';
import { ToastService } from 'services/toasts';
import { solveErrorMessage } from 'common/form/helpers';
import { getBCCategoryOptions } from '../helpers';
import { FormAutocompleteIIN } from 'common/components';
import { resolveTaxpayerNameOrFio } from 'common/helpers';
import { clearPrivateProps } from 'common/utils';

const initialValues = {};

type Props = ModalChildrenProps<boolean> & {
  className?: string;
};

export const BigcompanyAddModal: React.FC<Props> = ({ className, onClose }) => {
  const locale = useLocale();
  const { loc } = locale;

  const sibmitHandler = async (values: any, form: FormikHelpers<any>) => {
    try {
      const res = await mutationDictionaryBigCompanyAdd({
        body: clearPrivateProps({ ...values, tin: values._taxpayer?.tin }),
      });
      typeof onClose === 'function' && onClose(true);
      return res;
    } catch (error) {
      ToastService.showError(solveErrorMessage(error));
      form.setSubmitting(false);
    }
  };

  const BCCategoryOptions = useMemo(() => getBCCategoryOptions(locale, false), [locale]);

  return (
    <div className={clsx(className, s.wrapper)}>
      <ModalHeader>{loc('bigcomps.button.add')}</ModalHeader>
      <Formik<any> initialValues={initialValues} onSubmit={sibmitHandler}>
        {({ values, isSubmitting }) => (
          <Form>
            <Fragment>
              <ModalContent className={s.content}>
                <FormField name="_taxpayer" label={loc('bigcomps.tin')}>
                  <FormAutocompleteIIN companyName />
                </FormField>
                <Field label={loc('bigcomps.name')}>
                  <Input value={resolveTaxpayerNameOrFio(values._taxpayer, locale)} readOnly />
                </Field>
                <FormField name="category" label={loc('bigcomps.category')}>
                  <FormSelect options={BCCategoryOptions} />
                </FormField>
              </ModalContent>
              <ModalFooter>
                <Button
                  size="small"
                  variant="transparent"
                  onClick={() => typeof onClose === 'function' && onClose(false)}
                >
                  {loc('common.button.cancel')}
                </Button>
                <Button
                  type="submit"
                  size="small"
                  disabled={!(values._taxpayer?.tin && values.category)}
                  loading={isSubmitting}
                >
                  {loc('common.button.save')}
                </Button>
              </ModalFooter>
            </Fragment>
          </Form>
        )}
      </Formik>
    </div>
  );
};
