import React, { useState } from 'react';
import clsx from 'clsx';
import s from './PassportComments.module.scss';
import { Button, FormField, FormInput, Heading, Loader } from 'ui';
import { Form, Formik, FormikHelpers } from 'formik';
import { useAppMode, useStore } from 'common/hooks';
import {
  mutationAdminAssuranceCommentPost,
  mutationEaecAssuranceCommentPost,
  queryAdminAssuranceCommentGet,
  queryEaecAssuranceCommentGet,
} from '../api';
import { ToastService } from 'services/toasts';
import { solveErrorMessage } from 'common/form/helpers';
import { useDataQuery } from 'rest';
import { AppMode } from 'common/constants';

type Props = {
  className?: string;
  passportId?: string;
};

export const PassportComments: React.FC<Props> = ({ className, passportId }) => {
  const { locale, dialog } = useStore();
  const { loc } = locale;
  const appMode = useAppMode();

  const { data, refetch } = useDataQuery(
    appMode === AppMode.EAEC ? queryEaecAssuranceCommentGet : queryAdminAssuranceCommentGet,
    { passportId }
  );
  const [submitting, setSubmitting] = useState(false);

  const onSubmit = async (values: any, form: FormikHelpers<any>) => {
    const confirmed = await dialog.show({ content: loc('common.labels.sendComments'), center: true });
    if (confirmed) {
      setSubmitting(true);
      const mutation = appMode === AppMode.EAEC ? mutationEaecAssuranceCommentPost : mutationAdminAssuranceCommentPost;
      try {
        await mutation({ body: values, passportId });
        refetch();
        form.resetForm();
      } catch (error) {
        ToastService.showError(solveErrorMessage(error));
      } finally {
        setSubmitting(false);
      }
    }
  };

  return (
    <div className={clsx(className, s.wrapper)}>
      <Heading className={s.title} tag="h2">
        {loc('common.labels.comments')}
      </Heading>

      {Array.isArray(data) ? (
        <div className={s.list}>
          {data.map((comment: any, index) => (
            <div key={index} className={s.comment}>
              <span className={s.name}>{comment.userId}</span>
              <span className={s.text}>{comment.comment}</span>
              <span className={s.date}>{comment.date}</span>
            </div>
          ))}
        </div>
      ) : (
        <Loader className={s.listLoader} />
      )}

      {appMode === AppMode.EAEC && (
        <Formik onSubmit={onSubmit} initialValues={{}}>
          {({ isSubmitting }) => (
            <Form className={s.addCommentForm}>
              <FormField name="comment" className={s.field}>
                <FormInput placeholder={loc('common.labels.addComments')} className={s.input} maxLength={255} />
              </FormField>
              <Button type="submit" className={s.button}>
                {loc('common.button.send')}
              </Button>
              {submitting && <Loader overlay className={s.loaderOverlay} loaderClassName={s.loader} />}
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};
