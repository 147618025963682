import { FilterT2ContentProps, FormDoubleDatePickerT2 } from 'common/components';
import React from 'react';
import { useLocale } from 'services/l10n';
import { FieldT2 } from 'ui';

type Props = FilterT2ContentProps;

export const DocTransferFiltersMainTxp: React.FC<Props> = ({ form, classNames }) => {
  const locale = useLocale();
  const { loc } = locale;

  const { mainWrapper } = classNames || {};

  return (
    <div className={mainWrapper}>
      <FieldT2 label={loc('docTransfer.date')} style={{ width: '360px' }}>
        <FormDoubleDatePickerT2 nameFrom="startDate" nameTo="endDate" placeholderText="" />
      </FieldT2>
      {/* <FormFieldT2 style={{ marginLeft: 'auto', width: '360px' }} name="registrationNumber" noMargin>
        <FormInputT2 placeholder={loc('zvt.regNumberPlaceholder')} icon="search" />
      </FormFieldT2> */}
    </div>
  );
};
