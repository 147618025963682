import React, { useMemo } from 'react';
import { createCachedQuery, queryTaxpayerAutocomplete } from 'rest';
import { FormAutocomplete, FormAutocompleteProps } from 'ui/common';

type Props = FormAutocompleteProps & {
  iin?: string;
};

export const FormAutocompleteIIK: React.FC<Props> = ({ field, iin, ...rest }) => {
  const loadOptions = useMemo(() => getLoadOptions(iin), [iin]);

  return (
    <FormAutocomplete
      loadOptions={loadOptions}
      getOptionLabel={getOptionLabel}
      getSingleValue={getSingleValue}
      defaultOptions
      field={
        field
          ? {
              ...field,
              value: typeof field.value === 'string' ? { account: field.value } : field.value,
            }
          : undefined
      }
      {...rest}
    />
  );
};

const queryTaxpayerAutocompleteCached = createCachedQuery(queryTaxpayerAutocomplete);

function getLoadOptions(iin?: string) {
  return async function (term: string) {
    if (iin) {
      const { data } = await queryTaxpayerAutocompleteCached({ term: iin });
      if (data) {
        return [
          ...(Array.isArray(data.accounts) ? data.accounts : []),
          ...(Array.isArray(data.headOffice?.accounts) ? data.headOffice?.accounts : []),
        ];
      }
    }
    return [];
  };
}

const getOptionLabel = (data: any) => {
  return data.account;
};

const getSingleValue = (data: any) => {
  return data?.account || '';
};
