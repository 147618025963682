import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { DEFAULT_LOCALES, LocaleNames } from './helpers';
import { useLocale } from './useLocale';

type Props = {
  localeNames?: Array<LocaleNames>;
  withDefault?: boolean;
};

export const LocalesLoader: React.FC<Props> = ({ children, localeNames, withDefault }) => {
  const localeNamesForLoad: Array<LocaleNames> = useMemo(
    () => (withDefault ? Array.from(new Set([...DEFAULT_LOCALES, ...(localeNames || [])])) : localeNames || []),
    [localeNames, withDefault]
  );
  const locale = useLocale();
  const [loaded, setLoaded] = useState(locale.checkLocales(localeNamesForLoad));

  useEffect(() => {
    // console.log('LocalesLoader Effect', {
    //   localeNamesForLoad: JSON.stringify(localeNamesForLoad),
    //   loaded,
    //   loadedLocales: locale.loadedLocales,
    // });
    if (!loaded) {
      locale.checkAndUpdateLocales(localeNamesForLoad).then(() => {
        setLoaded(true);
        // console.log('locales LOADED: loadedLocales:', {
        //   loadedLocales: JSON.stringify(locale.loadedLocales),
        // });
      });
    }
  }, [localeNamesForLoad, locale, loaded]);

  return loaded ? <Fragment> {children} </Fragment> : null;
};
