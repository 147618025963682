import { Journal } from 'common/components/journal';
import { JournalButton } from 'common/components/journal/types/JournalButton';
import React, { Fragment, useMemo } from 'react';
import { downloadFile, useDataQuery } from 'rest';
import { useLocale } from 'services/l10n';
import { queryAdminDocTransferSearch } from '../api';
import { DocTransferFiltersArm } from './DocTransferFiltersArm';
import { getDocTransferColumnsArm } from './getDocTransferColumnsArm';
import { SessionStorageService } from 'services/storage';
import { API_URL } from 'common/constants';

const initialFilter = {};
const initialPagination = { rows: 10 };

const getRowId = (row: any) => row?.agreementId;

const filterStorageKey = 'armDocTransferFilters';

type Props = {};

/**
 * Страница АРМ -> Журнал соглашений (на передачу СНТ, АВР, ЭД и ЭСФ)
 * arm/doc-transfer
 */
export const DocTransferJournalArm: React.FC<Props> = () => {
  const locale = useLocale();
  const { loc } = locale;

  const columns = useMemo(() => getDocTransferColumnsArm({ locale }), [locale]);

  const queryProps = useDataQuery(queryAdminDocTransferSearch, {
    filter: SessionStorageService.get(filterStorageKey) || initialFilter,
    pagination: initialPagination,
  });

  const headerButtons: Array<JournalButton> = [];

  const actionButtons: Array<JournalButton> = [
    {
      id: 'pdf',
      label: loc('common.button.pdf'),
      onClick: async (selection) => {
        try {
          const res = await downloadFile({
            url: `${API_URL}/admin/docTransfer/agreement/report`,
            method: 'GET',
            params: { agreementId: getRowId(selection[0]) },
          });
          return res;
        } catch (error) {
          console.log(error);
        }
      },
    },
  ];

  return (
    <Fragment>
      <Journal
        pageTitle={loc('common.menu.docTransfer')}
        columns={columns}
        selectable
        queryProps={queryProps}
        filterComponent={DocTransferFiltersArm}
        filterInitialOpened
        defaultFilter={initialFilter}
        headerButtons={headerButtons}
        actionButtons={actionButtons}
        getRowId={getRowId}
        filterStorageKey={filterStorageKey}
      />
    </Fragment>
  );
};
