import React, { useMemo } from 'react';
import clsx from 'clsx';
import s from './TaxpayerLayoutAside.module.scss';
import { useLocale } from 'services/l10n';
import { NavigationItem, KgdLogo } from 'ui/common';
import { useUser } from 'common/hooks';
import { useAppMode } from 'common/hooks/useAppMode';
import { generateMenu } from 'common/helpers/menu';
import { Link } from 'react-router-dom';
import { PATH } from 'routing/paths';

type Props = {
  className?: string;
  minimized?: boolean;
};

export const TaxpayerLayoutAside: React.FC<Props> = ({ className, minimized }) => {
  const locale = useLocale();
  const user = useUser();

  const appMode = useAppMode();

  const menu = useMemo(() => generateMenu(appMode, locale, user), [appMode, locale, user]);

  return (
    <div className={clsx(className, s.wrapper)}>
      <div className={s.header}>
        <Link to={PATH.MAIN} className={s.logoLink}>
          <KgdLogo />
        </Link>
      </div>
      <div className={s.navigation}>
        {menu && menu.map((item) => <NavigationItem key={item.id} item={item} minimized={minimized} theme="T2" />)}
      </div>
    </div>
  );
};
