import React, { Fragment, useMemo, useState } from 'react';
import { useDataQuery } from 'rest';
import { ToastService } from 'services/toasts';
import { solveErrorMessage } from 'common/form/helpers';
import { ImportFromFileModal, Journal, JournalButton } from 'common/components';
import { getBigcompaniesJournalColumns } from './getBigcompaniesJournalColumns';
import { BigcompaniesJournalFilters } from './BigcompaniesJournalFilters';
import { SessionStorageService } from 'services/storage/StorageService';
import { rowsOptionsVariant3 } from 'ui';
import { Modal } from 'services/modal';
import {
  queryBigCompanyExport,
  mutationDictionaryBigCompanyImportFromFile,
  queryDictionaryBigCompanyImportResult,
  queryBigCompanyFindAll,
  mutationDictionaryBigCompanyUploadFile,
} from './api';
import { useStore } from 'common/hooks';
import { ADMIN_PERMISSION } from 'common/constants';
import { getResourceUrl } from 'common/helpers';
import { BigcompanyEditModal } from './bigcompanies-edit-modal/BigcompanyEditModal';
import { BigcompanyAddModal } from './bigcompanies-edit-modal/BigcompanyAddModal';

const initialPagination = { rows: 10 };

const getRowId = (row: any) => `${row?.tin}${row?.creationDate}`;

const filterStorageKey = 'Bigcompanies';

type Props = {
  className?: string;
};

/**
 * Страница АРМ, Справочники -> Крупные налогоплательщики
 * arm/big-companies
 */
export const BigcompaniesJournal: React.FC<Props> = ({ className }) => {
  const { locale, user } = useStore();
  const { loc } = locale;

  const [editModal, setEditModal] = useState<undefined | { show: 'add' } | { show: 'edit'; row: any }>(undefined);

  const [importModal, setImportModal] = useState<boolean>(false);

  const columns = useMemo(() => getBigcompaniesJournalColumns({ locale }), [locale]);

  const queryProps = useDataQuery(
    queryBigCompanyFindAll,
    {
      filter: SessionStorageService.get(filterStorageKey) || {},
      pagination: initialPagination,
    },
    { preProcessParams: (params) => ({ ...params, filter: preProcessFilter(params.filter) }) }
  );

  const exportJournal = async (selection: Array<any>, format: string) => {
    try {
      const res = await queryBigCompanyExport({
        filter: preProcessFilter(queryProps.filter),
        format,
      });
      return res;
    } catch (error) {
      ToastService.showError(solveErrorMessage(error));
    }
  };

  const headerButtons: Array<JournalButton> = [
    {
      id: 1,
      label: loc('common.button.add'),
      onClick: () => setEditModal({ show: 'add' }),
      disabled: () => !user.checkAdminPermission(ADMIN_PERMISSION.BIG_COMPANY_EDIT),
    },
    {
      id: 2,
      label: loc('common.button.import'),
      onClick: () => setImportModal(true),
      disabled: () => !user.checkAdminPermission(ADMIN_PERMISSION.BIG_COMPANY_EDIT),
    },
    {
      id: 3,
      label: loc('common.button.exportExcel'),
      onClick: (selection: Array<any>) => exportJournal(selection, 'XLSX'),
    },
  ];

  const actionButtons: Array<JournalButton> = [
    {
      id: 1,
      label: loc('common.button.edit'),
      onClick: (selected) => setEditModal({ show: 'edit', row: selected[0] }),
      disabled: () => !user.checkAdminPermission(ADMIN_PERMISSION.BIG_COMPANY_EDIT),
    },
    // {
    //   id: 'delete',
    //   label: loc('common.button.delete'),
    //   onClick: async (selected) => {
    //     const confirmed = await dialog.show({
    //       content: loc('bigcomps.confirmDelete'),
    //       center: true,
    //       confirmButtonText: loc('common.button.delete'),
    //     });
    //     if (confirmed) {
    //       try {
    //         const res = await mutationDictionaryBigCompanyDelete({
    //           body: { tins: JSON.stringify(selected.map((row) => row?.tin)) },
    //         });
    //         if (res.data?.success) {
    //           queryProps.refetch();
    //         }
    //       } catch (error) {
    //         ToastService.showError(solveErrorMessage(error));
    //       }
    //     }
    //   },
    //   multiselect: true,
    //   disabled: () => !user.checkAdminPermission(ADMIN_PERMISSION.BIG_COMPANY_EDIT),
    // },
  ];

  const onCloseEditModal = (needRefetch?: boolean) => {
    setEditModal(undefined);
    if (needRefetch) {
      queryProps.refetch();
    }
  };

  const onCloseImportModal = () => {
    queryProps.refetch();
    setImportModal(false);
  };

  return (
    <Fragment>
      <Journal
        pageTitle={loc('admin.menu.BIG_COMPANY')}
        columns={columns}
        queryProps={queryProps}
        filterComponent={BigcompaniesJournalFilters}
        filterInitialOpened={false}
        filterStorageKey={filterStorageKey}
        headerButtons={headerButtons}
        actionButtons={actionButtons}
        getRowId={getRowId}
        selectable
        paginationRowsOptions={rowsOptionsVariant3}
      />

      {!!editModal && (
        <Modal isOpen={true} onClose={onCloseEditModal} marginTop={150}>
          {editModal?.show === 'add' ? <BigcompanyAddModal /> : <BigcompanyEditModal data={editModal.row} />}
        </Modal>
      )}
      <Modal isOpen={importModal} onClose={onCloseImportModal}>
        <ImportFromFileModal
          onSubmit={mutationDictionaryBigCompanyImportFromFile}
          uploadFile={async (files: File[]) => {
            await mutationDictionaryBigCompanyUploadFile({ body: { file: files[0] } });
            return undefined;
          }}
          downloadResult={queryDictionaryBigCompanyImportResult}
          exampleLink={getResourceUrl('/data/downloads/big-company.xlsx')}
        />
      </Modal>
    </Fragment>
  );
};

const preProcessFilter = (filter: any) => ({
  ...filter,
});
