import { DataMutationFunction } from '../../types';
import { httpClient } from '../../httpClient';
import { convertToFormDataDeep } from 'rest/helpers';
import { clearEmptyFields } from 'common/utils';

export const mutationEgovLogin: DataMutationFunction = async (variables = {}): Promise<any> => {
  const { body = {} } = variables;
  let formData = convertToFormDataDeep(clearEmptyFields(body));

  const res = await httpClient.post('/ajax/egovLogin', formData);
  if (res.data?.success) {
    return res;
  } else {
    throw res;
  }
};
