import { DataMutationFunction } from '../../types';
import { httpClient } from '../../httpClient';

export const mutationArticlePublish: DataMutationFunction = async (variables = {}): Promise<any> => {
  const { id, certificate, sign } = variables;

  try {
    const res = await httpClient.put('/admin/news/publish', {}, { params: { id, certificate, sign } });
    if (res.data.success) {
      return res;
    } else {
      throw res.data.message;
    }
  } catch (error) {
    throw error;
  }
};
