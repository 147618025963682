import React from 'react';
import clsx from 'clsx';
import s from '../../InsidePage.module.scss';
import { Lang, useLocale } from 'services/l10n';
import { InsideMenu } from '../../InsideMenu';
import { InsideBreadcrumbs } from 'pages/inside-pages/InsideBreadcrumbs';
import img1 from 'assets/articles/derev_proekta_v_soap.jpg';
import img2 from 'assets/articles/testovyy_scenariy_v_soap.jpg';

type Props = {
  className?: string;
};

export const ApiPage: React.FC<Props> = ({ className }) => {
  const locale = useLocale();
  const { loc, lang } = locale;

  return (
    <div className={clsx(className, s.wrapper)}>
      <InsideBreadcrumbs section="forDevelopers" pageCrumbs={[{ label: loc('common.nav.api') }]} />
      <div className={s.content}>
        {lang === Lang.RU ? (
          <article className={s.article}>
            <h1>API — интерфейс Web-приложения "Электронные счета-фактуры"</h1>
            <h3>Уважаемые налогоплательщики!</h3>
            <p>
              В целях обеспечения эксплуатации информационной системы "Электронные счет-фактуры" (далее - ИС ЭСФ) и
              модуля "Виртуальный склад" посредством API - механизма, для Вас доступен комплект разработчика (SDK-
              software development kit) и документация по API, в которых определены методы, правила и порядок
              информационного взаимодействия учетных систем налогоплательщиков с ИС ЭСФ, модулем "Виртуальный склад", в
              том числе по работе с СНТ.
            </p>
            <p>В комлект разработчика входит:</p>
            <ul>
              <li>
                Документация по API (СНТ, ЭСФ, АВР, ЭД, справочников, формы сопоставления СНТ и ФНО-328, извещения СНТ)
              </li>
              <li>Шаблоны ЭСФ в XML формате</li>
              <li>Проект ЭСФ для SOAP</li>
              <li>Приложение генерации подписи</li>
              <li>Файлы библиотеки</li>
            </ul>
            <p>Для того чтобы понять как работает API механизм выполните следующие шаги:</p>
            <p>Шаг1:</p>
            <p>
              Скачать{' '}
              <a href="https://kgd.gov.kz/sites/default/files/ftpdata/ESF/esf-sdk-290824%20(1).rar">
                комплект разработчика
              </a>{' '}
              (документация ЭСФ SDK, документация ВС SDK, MACOSX )
            </p>
            <p>Шаг2:</p>
            <p>
              В папке localserver есть приложение которое позволит вам генерировать подпись. Его нужно запустить из
              консоли от имени Администратора вызовом файла start.bat
            </p>
            <p>Шаг 3:</p>
            <p>
              В паке soapui находится XML файл содержащий проект по ЭСФ. Проект нужно открыть в SoapUI который можно
              скачать на{' '}
              <a href="https://www.soapui.org/" target="__blank">
                официальном сайте
              </a>
              . Открыв проект найдете ссылки на описание методов и сервисов.
            </p>
            <p>Так же в нем предусмотрены два тестовых сценария поочередного выполнения всех операций.</p>
            <img src={img1} alt="derev_proekta_v_soap" />
            <p>Шаг 4:</p>
            <p>Запускайте сценарий, ожидаете завершения его выполнения.</p>
            <p>
              В окне сможете увидеть каждый шаг выполнения каждого запроса. При двойном клике на запрос вы сможете
              увидеть само тело запроса и ответ пришедший от системы.
            </p>
            <img src={img2} alt="testovyy_scenariy_v_soap" />
            <p>Результаты выполнения сценария можно увидеть на тестовом стенде ИС ЭСФ.</p>
            <p>Вход в в тестовый стенд можно осуществить с использованием собственных сертификатов.</p>
            <p>
              Подробное описание сервисов и методов вы сможете найти в документации, в проекте SOAP или по{' '}
              <a href="https://esf.gov.kz:8443/esf-web/ws/api1" target="__blank">
                ссылке
              </a>
            </p>
          </article>
        ) : (
          <article className={s.article}>
            <h1>Электрондық шот-фактуралар” Web қосымшасының API-интерфейсі</h1>
            <p>Құрметті салық төлеушілер!</p>
            <p>Сізге арналған әзірлеуші ​​жинағы қол жетімді:</p>
            <ul>
              <li>API құжаттамасы</li>
              <li>ЭШФ форматындағы XML үлгілері</li>
              <li>SOAP үшін ЭШФ жобасы</li>
              <li>Қолтаңбалауды қолдану</li>
              <li>Кітапханалық файлдар</li>
            </ul>
            <p>API механизмінің жұмыс істеу жолын түсіну үшін мына қадамдарды орындаңыз:</p>
            <p>1-қадам:</p>
            <p>
              Әзірлеушілер жиынтығын жүктеңіз{' '}
              <a href="https://kgd.gov.kz/sites/default/files/ftpdata/ESF/esf-sdk-290824%20(1).rar">developer kit.</a>{' '}
            </p>
            <p>2-қадам:</p>
            <p>
              Жергілікті серверлер қалтасында қолтаңбаны жасауға мүмкіндік беретін бағдарлама бар. Бұл start.bat файлын
              шақыру арқылы әкімші атынан консольден іске қосылуы керек.
            </p>
            <p>3-қадам:</p>
            <p>
              Soapui пакетінде ЭШФ жобасы туралы XML файлы бар. Жоба SoapUI-де ашылуы керек, оны ресми сайттан жүктеуге
              болады. Жобаны ашқаннан кейін Сіз әдістер мен қызметтер сипаттамасына сілтемелер таба аласыз.
            </p>
            <p>Ол сондай-ақ кез-келген операцияларды орындау үшін екі сынақ сценарийін ұсынады.</p>
            <img src={img1} alt="derev_proekta_v_soap" />
            <p>4-қадам:</p>
            <p>Сценарийді іске қосыңыз, оны аяқталуын күтіңіз.</p>
            <p>
              Терезеде әр сұраудың орындалуының әрбір қадамын көруге болады. Сұрауды екі рет басу арқылы сұрау органының
              өзі мен жүйеден алынған жауапты көре аласыз.
            </p>
            <img src={img2} alt="testovyy_scenariy_v_soap" />
            <p>Сценарийдің нәтижесін ЭШФ АЖ сынақ стендінде көруге болады.</p>
            <p>Сынақ стендке кіріп, сіздің жеке куәліктеріңізді қолданыңыз.</p>
            <p>
              Қызметтер мен әдістердің толық сипаттамасын құжаттамада, SOAP жобасында немесе сілтеме арқылы табуға
              болады{' '}
              <a href="https://esf.gov.kz:8443/esf-web/ws/api1" target="__blank">
                link.
              </a>
            </p>
          </article>
        )}

        <InsideMenu menuName="forDevelopers" className={s.insideMenu} />
      </div>
    </div>
  );
};
