import { clearEmptyFields } from 'common/utils';
import { DataMutationFunction, httpClient } from 'rest';

export const mutationDictionaryBigCompanyUpdate: DataMutationFunction = async (variables = {}): Promise<any> => {
  const { body = {} } = variables;

  const res = await httpClient.post('/dictionary/bigCompany/update', clearEmptyFields(body));
  if (res.data?.success === false) {
    throw res.data;
  } else {
    return res;
  }
};
