import { UserStore } from 'common/stores/user-store/UserStore';
import { PATH } from 'routing/paths';
import { LocaleStore } from 'services/l10n/LocaleStore';
import { ReactComponent as VStoreIcon } from 'assets/icons/vstore.svg';
import { ReactComponent as SntIcon } from 'assets/icons/snt.svg';
import { ReactComponent as EcontractIcon } from 'assets/icons/econtract.svg';
import { ReactComponent as AwpIcon } from 'assets/icons/awp.svg';
import { ReactComponent as EsfIcon } from 'assets/icons/esf.svg';
import { ReactComponent as DictsIcon } from 'assets/icons/dicts.svg';
import { MenuItem } from 'ui';
import { getLegasyUrl, getVStoreLegasyUrl } from '../getLegasyUrl';

export const mainMenu = ({ loc }: LocaleStore, user: UserStore): Array<MenuItem> => [
  {
    id: 10,
    label: loc('common.menu.regAccounting'),
    iconName: 'detail',
    link: getLegasyUrl('/reg-accounting'),
    externalLink: true,
    childrenItems: [
      {
        id: 21,
        label: loc('common.menu.myDetails'),
        link: getLegasyUrl('/reg-accounting/user-details'),
        externalLink: true,
      },
      {
        id: 22,
        label: loc('common.menu.ip'),
        link: getLegasyUrl('/reg-accounting/ip'),
        externalLink: true,
      },
      {
        id: 13,
        label: loc('common.menu.enterprises'),
        link: getLegasyUrl('/enterprises'),
        externalLink: true,
      },
      {
        id: 14,
        label: loc('common.menu.employees'),
        link: getLegasyUrl('/employees'),
        externalLink: true,
      },
      {
        id: 15,
        label: loc('common.menu.departments'),
        link: getLegasyUrl('/reg-accounting/branches'),
        externalLink: true,
      },
      {
        id: 16,
        label: loc('common.menu.operatorContractor'),
        link: getLegasyUrl('/reg-accounting/operator'),
        externalLink: true,
      },
      {
        id: 17,
        label: loc('common.menu.attorneyTruster'),
        link: getLegasyUrl('/reg-accounting/attorney'),
        externalLink: true,
      },
      {
        id: 18,
        label: loc('common.menu.reregistration'),
        link: getLegasyUrl('/reg-accounting/re-registration'),
        externalLink: true,
      },
      {
        id: 19,
        label: loc('common.menu.reorganization'),
        link: getLegasyUrl('/reorganization'),
        externalLink: true,
      },
      {
        id: 29,
        label: loc('common.menu.smallRetail'),
        link: `${PATH.SMALL_RETAIL}`,
      },
    ],
  },
  {
    id: 11,
    label: loc('common.menu.myDetails'),
    iconName: 'detail',
    link: getLegasyUrl('/reg-accounting/user-details'),
    externalLink: true,
  },
  {
    id: 12,
    label: loc('common.menu.enterprises'),
    iconName: 'detail',
    link: getLegasyUrl('/enterprises'),
    externalLink: true,
  },
  {
    id: 14,
    label: loc('common.menu.certificates'),
    iconName: 'detail',
    link: getLegasyUrl('/ess/certificates'),
    externalLink: true,
  },
  {
    id: 15,
    label: loc('common.econtract'),
    iconComp: EcontractIcon,
    link: getLegasyUrl('/e-contract'),
    externalLink: true,
  },
  {
    id: 16,
    label: loc('common.virtualStore'),
    iconComp: VStoreIcon,
    link: getVStoreLegasyUrl(),
    externalLink: true,
    childrenItems: [
      {
        id: 161,
        label: loc('common.menu.SALDO_REPORT'),
        link: PATH.SALDO_REPORT,
      },
      {
        id: 162,
        label: loc('common.menu.ZVT'),
        link: PATH.ZVT_JOURNAL,
      },
      {
        id: 'saldo',
        label: loc('common.menu.SALDO_REPORT'),
        link: PATH.SALDO_REPORT,
      },
    ],
  },
  {
    id: 25,
    label: loc('common.menu.assurance'),
    iconName: 'detail',
    link: PATH.ASSURANCE_JOURNAL,
  },
  {
    id: 17,
    label: loc('common.awp'),
    iconComp: AwpIcon,
    link: getLegasyUrl('/awp-journal'),
    externalLink: true,
  },
  {
    id: 18,
    label: loc('common.snt'),
    iconComp: SntIcon,
    link: PATH.SNT_JOURNAL,
    childrenItems: [
      {
        id: 21,
        label: loc('common.snt'),
        link: PATH.SNT_JOURNAL,
      },
      {
        id: 22,
        label: loc('common.menu.sntFnoMatching'),
        link: getLegasyUrl('/fno-matching/journal/kind/snt'),
        externalLink: true,
      },
      {
        id: 13,
        label: loc('common.menu.uttnFnoMatching'),
        link: getLegasyUrl('/fno-matching/journal/kind/form'),
        externalLink: true,
      },
      {
        id: 14,
        label: loc('common.menu.expiredNotification'),
        link: getLegasyUrl('/expired-notification/journal'),
        externalLink: true,
      },
      {
        id: 15,
        label: loc('common.menu.MPT'),
        link: getLegasyUrl('/mpt/journal'),
        externalLink: true,
      },
    ],
  },
  {
    id: 19,
    label: loc('common.menu.exportControl'),
    iconName: 'detail',
    link: getLegasyUrl('/permissiveDocuments/journal'),
    externalLink: true,
    childrenItems: [
      {
        id: 21,
        label: loc('common.menu.permissiveDocuments'),
        link: getLegasyUrl('/permissiveDocuments/journal'),
        externalLink: true,
      },
    ],
  },
  {
    id: 20,
    label: loc('common.esf'),
    iconComp: EsfIcon,
    link: PATH.ESF_JOURNAL,
    childrenItems: [
      {
        id: 201,
        label: loc('common.esf'),
        link: PATH.ESF_JOURNAL,
      },
      // {
      //   id: 202,
      //   label: loc('common.menu.ESF_LEGACY'),
      //   link: getLegasyUrl('/esf/journal'),
      //   externalLink: true,
      // },
      // {
      //   id: 203,
      //   label: loc('common.menu.ESF_GO_JOURNAL'),
      //   link: getLegasyUrl('/esf/go-journal'),
      //   externalLink: true,
      // },
      {
        id: 203,
        label: loc('common.menu.ESF_GO_JOURNAL'),
        link: getLegasyUrl('/esf/go-journal'),
        externalLink: true,
      },
      {
        id: 204,
        label: loc('common.menu.ESF_REPORTS'),
        link: getLegasyUrl('/esf/reports'),
        externalLink: true,
      },
    ],
  },
  {
    id: 21,
    label: loc('common.menu.settings'),
    iconName: 'detail',
    link: getLegasyUrl('/settings'),
    externalLink: true,
    childrenItems: [
      {
        id: 211,
        label: loc('common.menu.notifications'),
        link: getLegasyUrl('/settings/notifications-settings'),
        externalLink: true,
      },
      {
        id: 212,
        label: loc('common.menu.other_settings'),
        link: getLegasyUrl('/settings/other-settings'),
        externalLink: true,
      },
      {
        id: 213,
        label: loc('common.menu.settingsMoveVs'),
        link: PATH.SETTINGS_VS,
      },
      {
        id: 'docTransfer',
        label: loc('common.menu.docTransfer'),
        link: PATH.DOC_TRANSFER,
      },
    ],
  },
  {
    id: 22,
    label: loc('common.menu.dictionaries'),
    iconComp: DictsIcon,
    childrenDirUp: true,
    link: getLegasyUrl('/dictionaries'),
    externalLink: true,
    childrenItems: [
      {
        id: 21,
        label: loc('common.menu.CURRENCY_RATE'),
        link: getLegasyUrl('/dictionaries/currency-rates'),
        externalLink: true,
      },
      {
        id: 22,
        label: loc('common.menu.MEASURE_UNIT'),
        link: getLegasyUrl('/dictionaries/units'),
        externalLink: true,
      },
      {
        id: 23,
        label: loc('common.menu.GSVS'),
        link: getLegasyUrl('/dictionaries/gsvs'),
        externalLink: true,
      },
      {
        id: 24,
        label: loc('common.menu.OIL_PIN'),
        link: getLegasyUrl('/dictionaries/pin-oil'),
        externalLink: true,
      },
      {
        id: 25,
        label: loc('common.menu.TOBACCO_PIN'),
        link: getLegasyUrl('/dictionaries/pin-tobacco'),
        externalLink: true,
      },
      {
        id: 26,
        label: loc('common.menu.ALCOHOL_PIN'),
        link: getLegasyUrl('/dictionaries/pin-alcohol'),
        externalLink: true,
      },
      {
        id: 27,
        label: loc('common.menu.OIL_OVD'),
        link: getLegasyUrl('/dictionaries/ovd-oil'),
        externalLink: true,
      },
      {
        id: 28,
        label: loc('common.menu.TOBACCO_OVD'),
        link: getLegasyUrl('/dictionaries/ovd-tobacco'),
        externalLink: true,
      },
      {
        id: 29,
        label: loc('common.menu.ALCOHOL_LICENSE'),
        link: getLegasyUrl('/dictionaries/license-alcohol'),
        externalLink: true,
      },
    ],
  },
  // {
  //   id: 23,
  //   label: loc('common.menu.faq'),
  //   iconName: 'detail',
  //   link: LEGACY_URL ? `${LEGACY_URL}` : '/',
  //   externalLink: true,
  // },
  {
    id: 24,
    label: loc('common.menu.agreements'),
    iconName: 'detail',
    link: getLegasyUrl('/agreements'),
    externalLink: true,
  },
  {
    id: 111,
    label: 'Development',
    iconName: 'detail',
    link: PATH.DEV,
    disabled: process.env.NODE_ENV !== 'development',
  },
];
