import { LocaleStore } from 'services/l10n';
import { TableColumn } from 'ui/common';

export const getDocTransferColumnsArm = ({ locale: { loc } }: { locale: LocaleStore }): Array<TableColumn> => [
  {
    name: 'agreementNumber',
    label: loc('docTransfer.agreementNumber'),
  },
  {
    name: 'taxpayerTin',
    label: loc('common.labels.tin'),
  },
  {
    name: 'taxpayerName',
    label: loc('common.labels.npName'),
  },
  {
    name: 'startDate',
    label: loc('docTransfer.startDate'),
  },
  {
    name: 'endDate',
    label: loc('docTransfer.endDate'),
  },
  {
    name: 'agreementOrganizationTin',
    label: loc('docTransfer.agreementOrganizationTin'),
  },
  {
    name: 'agreementOrganizationName',
    label: loc('docTransfer.agreementOrganizationName'),
  },
  {
    name: 'docTypes',
    label: loc('docTransfer.docTypes'),
    getContent: (row, index, value) =>
      Array.isArray(value) ? value.map((doc) => loc(`docTransfer.docType.${doc}`)).join(', ') : '',
  },
];
