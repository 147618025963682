import { FormLocaleDatePicker, FilterContentProps } from 'common/components';
import React, { Fragment } from 'react';
import { useLocale } from 'services/l10n';
import { FormField, FormInput, FormMultiselect, FormSelect } from 'ui/common';
import { getDocTypesOptions } from '../constants';
import { getOptionsYesNo } from 'common/helpers';

type Props = FilterContentProps;

export const DocTransferFiltersArm: React.FC<Props> = ({ classNames, form }) => {
  const locale = useLocale();
  const { loc } = locale;

  const { columnClassName } = classNames || {};

  const docTypeOptions = getDocTypesOptions(locale);

  return (
    <Fragment>
      <div className={columnClassName}>
        <FormField name="startDate" label={`${loc('docTransfer.startDate')}:`}>
          <FormLocaleDatePicker maxDate={new Date()} />
        </FormField>
        <FormField name="endDate" label={`${loc('docTransfer.endDate')}:`}>
          <FormLocaleDatePicker />
        </FormField>
      </div>
      <div className={columnClassName}>
        <FormField name="taxpayerTin" label={loc('common.labels.tin')}>
          <FormInput />
        </FormField>
        <FormField name="agreementOrganizationTin" label={loc('docTransfer.agreementOrganizationTin')}>
          <FormInput />
        </FormField>
      </div>
      <div className={columnClassName}>
        <FormField name="isActive" label={loc('docTransfer.isActive')}>
          <FormSelect options={getOptionsYesNo(locale)} />
        </FormField>
      </div>
      <div className={columnClassName}>
        <FormField name="docTypes" label={loc('docTransfer.docTypes')}>
          <FormMultiselect options={docTypeOptions} variant="compact" />
        </FormField>
      </div>
    </Fragment>
  );
};
