import { DataMutationFunction } from '../../types';
import { httpClient } from '../../httpClient';

export const mutationNewsDelete: DataMutationFunction = async (variables = {}): Promise<any> => {
  const { id } = variables;

  try {
    const res = await httpClient.post('/admin/news/delete', {}, { params: { id } });
    if (res.data.success) {
      return res;
    } else {
      throw res.data.message;
    }
  } catch (error) {
    throw error;
  }
};
